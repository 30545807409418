import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { PolicyService } from 'src/app/services/policy.service';
import { ViewportScroller } from '@angular/common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { States } from 'src/config/metadata-config';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ClaimService } from 'src/app/services/claim.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AddressValidationComponent } from 'src/app/shared/components/address-validation/address-validation.component';
import { FlexAddressValidatorComponent } from 'src/app/shared/components/flex-layout/flex-address-validator/flex-address-validator.component';
import { AccountService } from 'src/app/services/account.service';
import { emptyVerificationInterface, IVerificationInterface } from 'src/app/shared/models/verification.interface';
import { FlexAppService } from 'src/app/services/flex-app.service';
import { environment as ENV } from '../../../../environments/environment';

@Component({
  selector: 'upsc-flexapp',
  templateUrl: './flexapp.component.html',
  styleUrls: ['./flexapp.component.scss']
})
export class FlexappComponent {

  flexibleCoverageFormGroup: UntypedFormGroup;
  //confirmFormGroup: UntypedFormGroup;
  stateList: any[] = States;
  addressList = [];
  currentAddress = null;
  loading: boolean = false;
  denied: boolean = false;
  showAddressValidation: boolean = false;
  systemError: boolean = false;
  currentStep: number = 1;
  loading2: boolean = false;
  verifyCodeError: boolean = false;
  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: "" };
  emailAddress: string = '';
  formValidationError: boolean = false;
  policyNumber: string = '';
  public showPassword: boolean;
  ONLLink = ENV.links.ONLLink;

  @ViewChild("name1") field1: ElementRef;
  @ViewChild("name2") field2: ElementRef;
  @ViewChild("name3") field3: ElementRef;
  @ViewChild("name4") field4: ElementRef;
  @ViewChild("name5") field5: ElementRef;
  @ViewChild("name6") field6: ElementRef;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private policyService: PolicyService,
    private scroller: ViewportScroller,
    public dialog: MatDialog,
    private claimService: ClaimService,
    private authService: AuthService,
    private flexAppService: FlexAppService
  ) {

    sessionStorage.setItem('userDetails', JSON.stringify({ type: 'flexApp' }));

    this.flexibleCoverageFormGroup = this.formBuilder.group({
      upsStoreNumber: ['', Validators.compose([Validators.required])],
      upsStoreNumberReenter: ['', Validators.compose([Validators.required])],
      upsAccountNumber: ['', Validators.compose([Validators.required])],
      jobTitle: ['', Validators.compose([Validators.required])],
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      emailAddress: ['', Validators.compose([Validators.required, Validators.email])],
      phoneNumber: ['', Validators.compose([Validators.required])],
      addressLineOne: ['', Validators.compose([Validators.required])],
      addressLineTwo: ['', Validators.compose([Validators.nullValidator])],
      city: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      zipCode: ['', Validators.compose([Validators.required])],
      // cbTermsOfServices: ['', Validators.compose([Validators.required])],
      cbTermsOfServices: ['', [(control) => {
        return !control.value ? { 'required': true } : null;
      }]],
      purchaseCoverage: [false, Validators.compose([Validators.nullValidator])],
      companyName: ['', Validators.compose([Validators.nullValidator])],
    });

    this.flexibleCoverageFormGroup.controls.upsStoreNumberReenter.valueChanges.subscribe(val => {
      //If re-entered password doesn't match password, set errors
      if (val !== this.flexibleCoverageFormGroup.controls.upsStoreNumber.value) {
        this.flexibleCoverageFormGroup.controls.upsStoreNumberReenter.setErrors({ 'Mismatched Store Number': true });
      }
    });
  }


  showCheckedHandler(cbEvent: boolean) {
    this.flexibleCoverageFormGroup.get("cbTermsOfServices").setValue(cbEvent);
  }

  dialogRef: any;
  validateAddress() {
    if (this.flexibleCoverageFormGroup.valid) {
      this.formValidationError = false;
      this.emailAddress = this.email.value;
      let config = new MatDialogConfig();
      config.autoFocus = false;
      config.closeOnNavigation = true;
      config.disableClose = true;
      config.width = '555px';
      // config.height = '550px';
      config.data = {
        customerName: this.firstName.value + ' ' + this.lastName.value,
        addressLine1: this.addressLineOne.value,
        addressLine2: this.addressLineTwo.value,
        city: this.city.value,
        countrycode: 'US',
        zipcode: this.zip.value,
        state: this.state.value
      };
      this.dialogRef = this.dialog
        .open(FlexAddressValidatorComponent, config)
        .afterClosed().subscribe(result => {
          if (result !== "-1") {
            try {
              var updateAddress = JSON.parse(sessionStorage.getItem("updatedVerifiedAddress"));
              this.systemError = false;
              if (updateAddress) {
                this.addressLineOne.setValue(updateAddress.AddressLine[0]);
                this.city.setValue(updateAddress.PoliticalDivision2);
                this.zip.setValue(updateAddress.PostcodePrimaryLow);
                var selectedState = this.stateList.find(x => x.state === updateAddress.PoliticalDivision1);
                if (selectedState) {
                  this.state.setValue(selectedState.id);
                }
              }
            } catch {
              if (updateAddress == null) {
                this.systemError = false;
              } else { this.systemError = true; }
            }
          } else {
            this.saveVerificationCode();
          }
        });
    }
    else {
      this.validateAllFormFields(this.flexibleCoverageFormGroup);
      // window.scrollTo(0, 0);
      window.scrollTo(screen.width / 2, screen.height / 2);
      this.formValidationError = true;
      //this.systemError = true;
      return false;
    }
  }

  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  cancel() {

  }

  showChecked($event) {

  }


  get upsStoreNumber() { return this.flexibleCoverageFormGroup.controls.upsStoreNumber }
  get upsAccountNumber() { return this.flexibleCoverageFormGroup.controls.upsAccountNumber }
  get jobTitle() { return this.flexibleCoverageFormGroup.controls.jobTitle }
  get firstName() { return this.flexibleCoverageFormGroup.controls.firstName }
  get lastName() { return this.flexibleCoverageFormGroup.controls.lastName }
  get email() { return this.flexibleCoverageFormGroup.controls.emailAddress }
  get addressLineOne() { return this.flexibleCoverageFormGroup.controls.addressLineOne }
  get addressLineTwo() { return this.flexibleCoverageFormGroup.controls.addressLineTwo }
  get zip() { return this.flexibleCoverageFormGroup.controls.zipCode }
  get city() { return this.flexibleCoverageFormGroup.controls.city }
  get state() { return this.flexibleCoverageFormGroup.controls.state }
  get companyName() { return this.flexibleCoverageFormGroup.controls.companyName }
  get phoneNumber() { return this.flexibleCoverageFormGroup.controls.phoneNumber }
  get purchaseCoverage() { return this.flexibleCoverageFormGroup.controls.purchaseCoverage }


  updateAddress123(address) {
    // this.showAddressValidation = false;
    if (address) {
      this.addressLineOne.setValue(address.AddressLine[0]);
      this.state.setValue(address.PoliticalDivision1);
      this.city.setValue(address.PoliticalDivision2);
      this.zip.setValue(address.PostcodePrimaryLow);
    }
  }

  gotoStepOne() {
    this.currentStep = 1;
  }

  public saveVerificationCode() {
    this.createVerificationInterface.data.emailAddress = this.email.value;
    this.accountService.createVerificationCode(this.createVerificationInterface).subscribe(
      data => {
        if ((data.responseMessage).toLowerCase() === 'new verification code created' ||
          (data.responseMessage).toLowerCase() === 'code not yet expired') {
          this.currentStep = 2;
        }
      }, error => {
        this.systemError = true;
      }
    );
  }

  cancelFromVerification() {
    this.currentStep = 1;
  }

  resendCode() {
    this.saveVerificationCode();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode != 43 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  codeVerifiedResponse($event) {
    if ($event === 'resetStep') {
      this.cancelFromVerification();
    }
    else if ($event === 'verifyCode') {

      let request = {
        jobTitle: this.jobTitle.value,
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        addressLine1: this.addressLineOne.value,
        addressLine2: this.addressLineTwo.value,
        city: this.city.value,
        state: this.state.value,
        zip: this.zip.value,
        phone: this.phoneNumber.value,
        country: 'US',
        email: this.email.value,
        upsStoreNumber: this.upsStoreNumber.value,
        uspsShipperNumber: this.upsAccountNumber.value,
        triaCoverage: this.purchaseCoverage.value === true ? 'Elect' : 'Reject',
        companyName: this.companyName.value
      };

      this.flexAppService.retrieveCbpPolicy(request).subscribe(response => {
        if (response && response !== null && response.policyNumber.length > 1) {
          this.policyNumber = response.policyNumber;
          this.currentStep = 3;
        } else {
          this.currentStep = 4;
        }
      }, error => {
        this.currentStep = 4;
      });
    }
    else {
      this.resendCode();
    }
  }

}
