import { Component, HostListener, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { ClaimService } from '../../../../services/claim.service';
import { IClaim, IClaimsDetails, emptyClaim } from '../../../../shared/models/claim.interface';
import { IPolicies, POLICYTYPES } from '../../../../shared/models/policies.interface';
import { LoginService } from 'src/app/services/login.service';
import { ExportService } from '../../../../services/export.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { UserService } from 'src/app/services/user.service';
import { PayeeService } from 'src/app/services/payee.service';
import localMetaData from '../../../../../assets/full-metadata.json';
import { countries } from 'src/config/metadata-config';
import { TranslateService } from '@ngx-translate/core';
import { ALLOWED_DOC_TYPES } from 'src/config/app-config';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ReopenConfirmationComponent } from '../../dialogs/reopen-confirmation/reopen-confirmation.component';
import { ResubmitClaim } from 'src/app/shared/models/resubmit-claim';
import { AccountInfo } from '../../../../shared/models/account-info.interface';
import { ReopenClaim } from 'src/app/shared/models/reopen-claim.interface';
import { WithdrawPopupComponent } from 'src/app/shared/components/withdraw-popup/withdraw-popup.component';
import { ReopenMessageComponent } from '../../dialogs/reopen-message/reopen-message.component';
import { ReOpenMessage } from 'src/app/shared/models/reopen-message';
import { ViewportScroller } from "@angular/common";
import { UserPermission } from 'src/app/shared/models/user-permission';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'upsc-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.persistSearch();
  }

  //@ViewChild('mValue') mValue: ElementRef;

  FILENAME: string = this.translateService.instant(this.translateService.instant('claimsDetail.detail'));

  //Interfaces
  claim: IClaimsDetails = {};
  claimResubmit: IClaim = emptyClaim;
  policy: IPolicies;
  policyType: POLICYTYPES;
  files: any = [];
  listOfFiles = new Array();
  resubmitClaimDetails: ResubmitClaim = new ResubmitClaim();
  public userDetails: AccountInfo = {};
  public reOpenClaim: ReopenClaim = {};

  reopenMessage: ReOpenMessage = new ReOpenMessage();
  notesNotPresent: string = "";
  isNotePresent: boolean = false;
  notesData: string[] = [];

  //HTML display helpers
  stateList: any[] = Object.entries(localMetaData.metaData.states).map(([key, value]) => ({ key, value }));
  countryList: any[] = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });//translated countries
  statusDetails = '';
  insured: string;  //Used for opening the email with subject line
  selectedClaimNumber: string;
  isMessage: boolean = false;
  sgDocuments = []; //System-generated documents
  uuDocuments = []; //User-uploaded documents
  showFileProgress = false;
  fileTooBig = false;
  fileNameError = false;
  fileTypeError = false;
  systemError = false;

  isDDUser: boolean;
  isGuestUser = false;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  currencyType: string;
  currentStep: any = 0;
  other: boolean = false;
  isReopen: boolean = false;
  claimDetailsTitle: string = "";
  claimedAmount: any;
  isWithdrawn: boolean = false;
  isWithdrawable: boolean = false;
  withdrawnClaim: any = {
    userPolicyNumber: "",
    userMessage: "",
    userClaimNumber: "",
    userObjectUID: ""
  };
  claimFileDate: any;
  resubmitFormGroup: UntypedFormGroup;
  errorNotification: boolean = false;
  submittedClaimNumber: string = "";
  continuing: boolean = false;

  damage: boolean = false;
  missing: boolean = false;
  late: boolean = false;
  lossDescription: any;
  isReturnShipment: boolean = false;

  cargo: boolean = false;
  flex: boolean = false;
  isOtherCollapsed: boolean = true;
  isMessageCollapsed: boolean = true;
  toggleMessage: boolean = false;

  public maxDate = new Date();
  public userPermission: UserPermission = new UserPermission();
  public claimReasons: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.reason.loss') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.reason.damage') },
    { id: 2, text: this.translateService.instant('claims.edit.whatHappened.reason.missing') },
    { id: 3, text: this.translateService.instant('claims.edit.whatHappened.reason.late') },
  ];

  public repairables: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.reason.yes') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.reason.no') },
  ];

  public damagedLocations: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.one') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.two') },
    { id: 2, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.three') }
  ];

  //permissions variables
  canMessageAdjuster: boolean = false;

  isBackPage: boolean = false;

  constructor(
    private claimService: ClaimService,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private excelService: ExportService,
    private route: ActivatedRoute,
    private userService: UserService,
    private payeeService: PayeeService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private router: Router,
    private scroller: ViewportScroller,
    private policyService: PolicyService
  ) {
    this.policy = this.loginService.getPolicyDetails();
    this.policyType = this.policyService.determinePolicyType(this.policy);
    this.insured = this.policy.insured;
    this.insured = encodeURIComponent(this.insured);
    this.isDDUser = this.userService.isDeliveryDefenseUser();
    this.isGuestUser = this.userService.isGuestUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.determineMessaging();

    this.userPermission = this.userService.getUserPermissions();
    var claimNumber = this.route.snapshot.queryParamMap.get('claimNumber');
    if (claimNumber) {
      this.selectedClaimNumber = claimNumber;
    }

    var isMessage = this.route.snapshot.queryParamMap.get('isMessage');
    if (isMessage) {
      if (isMessage === "true") {
        this.isMessage = true;
      }
      else {
        this.isMessage = false;
      }

      if (sessionStorage.getItem("backPage") === 'Dashboard') {
        this.isBackPage = false;
      }
      else
        this.isBackPage = true;
    }

    if (this.isMessage) {
      this.toggleMessage = true;
      this.isOtherCollapsed = true;
      this.isMessageCollapsed = false;
    }
    else {
      this.toggleMessage = false;
      this.isOtherCollapsed = false;
      this.isMessageCollapsed = true;
    }

  }

  loadMessages(event) {
    this.toggleMessage = event;
  }

  cancelReopen() {
    this.isReopen = false;
    this.claimDetailsTitle = this.translateService.instant('claims.edit.review.reviewAndSubmit.reviewList.claimDetail');
    window.scrollTo(0, 0);
  }

  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  formLossDescription() {
    let lossDescription = null;
    lossDescription = {
      //shipmentRole: this.role.value,
      reason: this.resubmitClaimDetails.resubmissionReason,
      care: this.resubmitClaimDetails.lateCare,
      careDetails: this.resubmitClaimDetails.lateCare === '0' ? this.resubmitClaimDetails.lateReship : this.resubmitClaimDetails.lateCare === '1' ? this.resubmitClaimDetails.lateRefund : '',
    }
    //Fill up dynamic data
    if (this.resubmitClaimDetails.resubmissionReason === 0) {
      //loss

      //loss
      var detailsCare = "";
      if (this.resubmitClaimDetails.lateCare && this.resubmitClaimDetails.lateCare === "0") {
        if (this.resubmitClaimDetails.lateReship) {
          detailsCare = this.resubmitClaimDetails.lateReship;
        }
      }
      else if (this.resubmitClaimDetails.lateCare && this.resubmitClaimDetails.lateCare === "1") {
        if (this.resubmitClaimDetails.lateRefund) {
          detailsCare = this.resubmitClaimDetails.lateRefund;
        }
      }

      lossDescription = {
        ...lossDescription,
        care: this.resubmitClaimDetails.lateCare,
        //careDetails: this.resubmitClaimDetails.lateCare === '0' ? this.resubmitClaimDetails.lateReship : this.resubmitClaimDetails.lateCare === '1' ? this.resubmitClaimDetails.lateRefund : '',
        careDetails: detailsCare,
        return: this.resubmitClaimDetails.isReturnShipment,
        originalTrackingNumber: this.resubmitClaimDetails.originalTrackNumber
      }
    } else if (this.resubmitClaimDetails.resubmissionReason === 1) {
      //damage
      lossDescription = {
        ...lossDescription,
        damageDescription: this.resubmitClaimDetails.damageDescription,
        damageDate: this.resubmitClaimDetails.damageDate,
        repairable: this.resubmitClaimDetails.damageRepairable,
        packageLocation: this.resubmitClaimDetails.damageLocations,
        packageLocationDetails: this.resubmitClaimDetails.damageLocationDetails,
        return: this.resubmitClaimDetails.isReturnShipment,
        originalTrackingNumber: this.resubmitClaimDetails.originalTrackNumber
      }
    } else if (this.resubmitClaimDetails.resubmissionReason === 2) {
      //missing
      lossDescription = {
        ...lossDescription,
        missingDescription: this.resubmitClaimDetails.missingDescription,
        missingDate: this.resubmitClaimDetails.missingDate,
        packageLocation: this.resubmitClaimDetails.missingLocations,
        packageLocationDetails: this.resubmitClaimDetails.missingLocationDetails,
        return: this.resubmitClaimDetails.isReturnShipment,
        originalTrackingNumber: this.resubmitClaimDetails.originalTrackNumber
      }
    } else if (this.resubmitClaimDetails.resubmissionReason === 3) {
      //late

      var detailsCare = "";
      if (this.resubmitClaimDetails.lateCare && this.resubmitClaimDetails.lateCare === "0") {
        if (this.resubmitClaimDetails.lateReship) {
          detailsCare = this.resubmitClaimDetails.lateReship;
        }
      }
      else if (this.resubmitClaimDetails.lateCare && this.resubmitClaimDetails.lateCare === "1") {
        if (this.resubmitClaimDetails.lateRefund) {
          detailsCare = this.resubmitClaimDetails.lateRefund;
        }
      }

      lossDescription = {
        ...lossDescription,
        care: this.resubmitClaimDetails.lateCare,
        //careDetails: this.resubmitClaimDetails.lateCare === '0' ? this.resubmitClaimDetails.lateReship : this.resubmitClaimDetails.lateCare === '1' ? this.resubmitClaimDetails.lateRefund : '',
        careDetails: detailsCare,
        return: this.resubmitClaimDetails.isReturnShipment,
        originalTrackingNumber: this.resubmitClaimDetails.originalTrackNumber
      }
    }
    return JSON.stringify(lossDescription);
  }

  resubmitClaim() {

    if (this.resubmitClaimDetails.resubmissionReason === 0) {

      this.claimResubmit.lossCause = "loss_Ext";

      // Damage
      this.resubmitFormGroup.controls.damageDescription.clearValidators();
      this.resubmitFormGroup.controls.damageDescription.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageDescription.updateValueAndValidity();

      this.resubmitFormGroup.controls.damagemissingDate.clearValidators();
      this.resubmitFormGroup.controls.damagemissingDate.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damagemissingDate.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageRepairable.clearValidators();
      this.resubmitFormGroup.controls.damageRepairable.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageRepairable.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageLocations.clearValidators();
      this.resubmitFormGroup.controls.damageLocations.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageLocations.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageLocationDetails.clearValidators();
      this.resubmitFormGroup.controls.damageLocationDetails.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageLocationDetails.updateValueAndValidity();


      // Missing
      this.resubmitFormGroup.controls.missingLocationDetails.clearValidators();
      this.resubmitFormGroup.controls.missingLocationDetails.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingLocationDetails.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingDate.clearValidators();
      this.resubmitFormGroup.controls.missingDate.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingDate.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingLocations.clearValidators();
      this.resubmitFormGroup.controls.missingLocations.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingLocations.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingDescription.clearValidators();
      this.resubmitFormGroup.controls.missingDescription.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingDescription.updateValueAndValidity();

      if (this.late) {

        this.resubmitFormGroup.controls.lateCare.setValidators([Validators.required]);
        this.resubmitFormGroup.controls.lateCare.updateValueAndValidity();

        if (this.resubmitClaimDetails.lateCare === "0") {
          this.resubmitFormGroup.controls.lateReship.setValidators([Validators.required]);
          this.resubmitFormGroup.controls.lateReship.updateValueAndValidity();

          this.resubmitFormGroup.controls.lateRefund.clearValidators();
          this.resubmitFormGroup.controls.lateRefund.setValidators([Validators.nullValidator]);
          this.resubmitFormGroup.controls.lateRefund.updateValueAndValidity();
        }
        else {
          this.resubmitFormGroup.controls.lateRefund.setValidators([Validators.required]);
          this.resubmitFormGroup.controls.lateRefund.updateValueAndValidity();

          this.resubmitFormGroup.controls.lateReship.clearValidators();
          this.resubmitFormGroup.controls.lateReship.setValidators([Validators.nullValidator]);
          this.resubmitFormGroup.controls.lateReship.updateValueAndValidity();
        }

      }
      else {
        this.resubmitFormGroup.controls.lateReship.clearValidators();
        this.resubmitFormGroup.controls.lateCare.setValidators([Validators.nullValidator]);
        this.resubmitFormGroup.controls.lateCare.updateValueAndValidity();

        this.resubmitFormGroup.controls.lateRefund.clearValidators();
        this.resubmitFormGroup.controls.lateRefund.setValidators([Validators.nullValidator]);
        this.resubmitFormGroup.controls.lateRefund.updateValueAndValidity();

        this.resubmitFormGroup.controls.lateReship.clearValidators();
        this.resubmitFormGroup.controls.lateReship.setValidators([Validators.nullValidator]);
        this.resubmitFormGroup.controls.lateReship.updateValueAndValidity();
      }

    } else if (this.resubmitClaimDetails.resubmissionReason === 1) {
      this.claimResubmit.lossCause = "damage_Ext";

      // Damage
      this.resubmitFormGroup.controls.damageDescription.setValidators([Validators.maxLength(800)]);
      this.resubmitFormGroup.controls.damageDescription.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.damageDescription.updateValueAndValidity();

      this.resubmitFormGroup.controls.damagemissingDate.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.damagemissingDate.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageRepairable.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.damageRepairable.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageLocations.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.damageLocations.updateValueAndValidity();

      this.resubmitFormGroup.controls.reopenNotes.setValidators([Validators.maxLength(250)]);
      this.resubmitFormGroup.controls.reopenNotes.updateValueAndValidity();

      if (this.resubmitClaimDetails.damageLocations === "3") {
        this.resubmitFormGroup.controls.damageLocationDetails.setValidators([Validators.required]);
        this.resubmitFormGroup.controls.damageLocationDetails.updateValueAndValidity();
      }
      else {
        this.resubmitFormGroup.controls.damageLocationDetails.clearValidators();
        this.resubmitFormGroup.controls.damageLocationDetails.setValidators([Validators.nullValidator]);
        this.resubmitFormGroup.controls.damageLocationDetails.updateValueAndValidity();
      }

      // Missing
      this.resubmitFormGroup.controls.missingLocationDetails.clearValidators();
      this.resubmitFormGroup.controls.missingLocationDetails.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingLocationDetails.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingDate.clearValidators();
      this.resubmitFormGroup.controls.missingDate.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingDate.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingLocations.clearValidators();
      this.resubmitFormGroup.controls.missingLocations.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingLocations.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingDescription.clearValidators();
      this.resubmitFormGroup.controls.missingDescription.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingDescription.updateValueAndValidity();


      // Late
      this.resubmitFormGroup.controls.lateCare.clearValidators();
      this.resubmitFormGroup.controls.lateCare.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.lateCare.updateValueAndValidity();

      this.resubmitFormGroup.controls.lateRefund.clearValidators();
      this.resubmitFormGroup.controls.lateRefund.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.lateRefund.updateValueAndValidity();

      this.resubmitFormGroup.controls.lateReship.clearValidators();
      this.resubmitFormGroup.controls.lateReship.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.lateReship.updateValueAndValidity();

    } else if (this.resubmitClaimDetails.resubmissionReason === 2) {

      this.claimResubmit.lossCause = "fallout_Ext";

      // Missing
      this.resubmitFormGroup.controls.missingDate.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.missingDate.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingDescription.setValidators([Validators.maxLength(800)]);
      this.resubmitFormGroup.controls.missingDescription.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.missingDescription.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingLocations.setValidators([Validators.maxLength(800)]);
      this.resubmitFormGroup.controls.missingLocations.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.missingLocations.updateValueAndValidity();

      if (this.resubmitClaimDetails.missingLocations === "3") {

        this.resubmitFormGroup.controls.missingLocationDetails.setValidators([Validators.required]);
        this.resubmitFormGroup.controls.missingLocationDetails.updateValueAndValidity();
      }
      else {
        this.resubmitFormGroup.controls.missingLocationDetails.clearValidators();
        this.resubmitFormGroup.controls.missingLocationDetails.setValidators([Validators.nullValidator]);
        this.resubmitFormGroup.controls.missingLocationDetails.updateValueAndValidity();
      }

      // Damage
      this.resubmitFormGroup.controls.damagemissingDate.clearValidators();
      this.resubmitFormGroup.controls.damagemissingDate.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damagemissingDate.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageDescription.clearValidators();
      this.resubmitFormGroup.controls.damageDescription.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageDescription.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageRepairable.clearValidators();
      this.resubmitFormGroup.controls.damageRepairable.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageRepairable.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageLocations.clearValidators();
      this.resubmitFormGroup.controls.damageLocations.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageLocations.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageLocationDetails.clearValidators();
      this.resubmitFormGroup.controls.damageLocationDetails.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageLocationDetails.updateValueAndValidity();

      // Late
      this.resubmitFormGroup.controls.lateCare.clearValidators();
      this.resubmitFormGroup.controls.lateCare.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.lateCare.updateValueAndValidity();

      this.resubmitFormGroup.controls.lateRefund.clearValidators();
      this.resubmitFormGroup.controls.lateRefund.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.lateRefund.updateValueAndValidity();

      this.resubmitFormGroup.controls.lateReship.clearValidators();
      this.resubmitFormGroup.controls.lateReship.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.lateReship.updateValueAndValidity();

    } else if (this.resubmitClaimDetails.resubmissionReason === 3) {
      this.claimResubmit.lossCause = "delay";

      // Late Delivery
      this.resubmitFormGroup.controls.lateCare.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.lateCare.updateValueAndValidity();

      if (this.resubmitClaimDetails.lateCare === "0") {
        this.resubmitFormGroup.controls.lateReship.setValidators([Validators.required]);
        this.resubmitFormGroup.controls.lateReship.updateValueAndValidity();

        this.resubmitFormGroup.controls.lateRefund.clearValidators();
        this.resubmitFormGroup.controls.lateRefund.setValidators([Validators.nullValidator]);
        this.resubmitFormGroup.controls.lateRefund.updateValueAndValidity();
      }
      else {
        this.resubmitFormGroup.controls.lateRefund.setValidators([Validators.required]);
        this.resubmitFormGroup.controls.lateRefund.updateValueAndValidity();

        this.resubmitFormGroup.controls.lateReship.clearValidators();
        this.resubmitFormGroup.controls.lateReship.setValidators([Validators.nullValidator]);
        this.resubmitFormGroup.controls.lateReship.updateValueAndValidity();
      }

      // Damage
      this.resubmitFormGroup.controls.damageDescription.clearValidators();
      this.resubmitFormGroup.controls.damageDescription.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageDescription.updateValueAndValidity();

      this.resubmitFormGroup.controls.damagemissingDate.clearValidators();
      this.resubmitFormGroup.controls.damagemissingDate.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damagemissingDate.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageRepairable.clearValidators();
      this.resubmitFormGroup.controls.damageRepairable.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageRepairable.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageLocations.clearValidators();
      this.resubmitFormGroup.controls.damageLocations.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageLocations.updateValueAndValidity();

      this.resubmitFormGroup.controls.damageLocationDetails.clearValidators();
      this.resubmitFormGroup.controls.damageLocationDetails.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.damageLocationDetails.updateValueAndValidity();


      // Missing
      this.resubmitFormGroup.controls.missingLocationDetails.clearValidators();
      this.resubmitFormGroup.controls.missingLocationDetails.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingLocationDetails.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingDate.clearValidators();
      this.resubmitFormGroup.controls.missingDate.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingDate.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingLocations.clearValidators();
      this.resubmitFormGroup.controls.missingLocations.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingLocations.updateValueAndValidity();

      this.resubmitFormGroup.controls.missingDescription.clearValidators();
      this.resubmitFormGroup.controls.missingDescription.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.missingDescription.updateValueAndValidity();

    }

    if (this.resubmitClaimDetails.isReturnShipment === "yes") {
      this.resubmitFormGroup.controls.originalTrackingNumber.setValidators([Validators.required]);
      this.resubmitFormGroup.controls.originalTrackingNumber.updateValueAndValidity();
    }
    else {
      this.resubmitFormGroup.controls.originalTrackingNumber.clearValidators();
      this.resubmitFormGroup.controls.originalTrackingNumber.setValidators([Validators.nullValidator]);
      this.resubmitFormGroup.controls.originalTrackingNumber.updateValueAndValidity();
    }

    if (this.resubmitFormGroup.valid) {

      this.continuing = true;
      this.errorNotification = false;

      // Assigning Loss Cause to claim object for resubmit
      if (this.resubmitClaimDetails.resubmissionReason === 0) {
        this.claimResubmit.lossCause = "loss_Ext";
      } else if (this.resubmitClaimDetails.resubmissionReason === 1) {
        this.claimResubmit.lossCause = "damage_Ext";
      } else if (this.resubmitClaimDetails.resubmissionReason === 2) {
        this.claimResubmit.lossCause = "fallout_Ext";
      } else if (this.resubmitClaimDetails.resubmissionReason === 3) {
        this.claimResubmit.lossCause = "delay";
      }

      // Assign values to claim object for resubmit
      if (this.resubmitClaimDetails.reopenNotes !== undefined
        && this.resubmitClaimDetails.reopenNotes !== null) {
        this.claimResubmit.additionalNotes = this.resubmitClaimDetails.reopenNotes;
      }

      this.claimResubmit.claimAmount_Ext = this.claim.claimedAmount;
      this.claimResubmit.trackingNumber_Ext = this.claim.tracking;
      this.claimResubmit.transportationCarrierId_Ext = this.claim.carrier;
      this.claimResubmit.shipDate = this.claim.shipDate;
      this.claimResubmit.lossDate = this.claim.shipDate;

      this.policy = this.loginService.getPolicyDetails();
      this.claimResubmit.policy.policyNumber = this.policy.policyNumber;
      this.claimResubmit.policy.effectiveDate = this.policy.effectiveDate;
      this.claimResubmit.policy.expirationDate = this.policy.expirationDate;
      this.claimResubmit.policy.insured = this.policy.insured;
      this.claimResubmit.policy.policyType = this.policy.policyType.key;

      this.claimResubmit.shippingDetailsDTO.verifiedShippingCharge = parseFloat(this.claimService.formatAmount(this.resubmitClaimDetails.shippingAmount, false)).toFixed(2);
      this.claimResubmit.shippingDetailsDTO.merchandiseValue = parseFloat(this.claimService.formatAmount(this.resubmitClaimDetails.merchandiseAmount, false)).toFixed(2);
      this.claimResubmit.shippingDetailsDTO.merchandiseDescription = this.resubmitClaimDetails.merchandiseDescription;

      var amountInvoice = parseFloat(this.claimService.formatAmount(this.resubmitClaimDetails.shippingAmount, false)) +
        parseFloat(this.claimService.formatAmount(this.resubmitClaimDetails.merchandiseAmount, false));

      this.claimResubmit.shippingDetailsDTO.invoiceAmount = amountInvoice.toString();
      this.claimResubmit.claimAmount_Ext = amountInvoice.toString();

      if (this.userDetails.userRoleID == '1') {
        this.claimResubmit.userRole = 'ONL-Admin';  //Must have no spaces
      } else if (this.userDetails.userRoleID == '3') {
        this.claimResubmit.userRole = 'ONL-Guest';
      } else if (this.userDetails.userRoleID == '4') {
        this.claimResubmit.userRole = 'ONL-COI';
      }

      if (this.resubmitClaimDetails.resubmissionReason === 0) {
        this.claimResubmit.lossDescription = this.formLossDescription();
        //this.claimResubmit.reshipTrackingNumber = this.whatHappenedFormGroup.value.lossReship ? this.whatHappenedFormGroup.value.lossReship : '';
        //this.claimResubmit.creditMemoRefundNo = this.whatHappenedFormGroup.value.lossRefund ? this.whatHappenedFormGroup.value.lossRefund : '';
        this.claimResubmit.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
      } else if (this.resubmitClaimDetails.resubmissionReason === 1) {
        //this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
        //this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
        this.claimResubmit.lossDescription = this.formLossDescription();
        //this.claimResubmit.repairableDisplay = this.whatHappenedFormGroup.value.damageRepairable ? this.repairables[this.whatHappenedFormGroup.value.damageRepairable].text : '';
        if (Number.parseInt(this.resubmitClaimDetails.damageLocations) < 3) {
          if (this.resubmitFormGroup.value.damageLocations != '') {
            //this.locationDisplay = this.whatHappenedFormGroup.value.damageLocations ? this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text : '';
            this.claimResubmit.shipmentStatus = this.resubmitClaimDetails.damageLocations ? this.damagedLocations[this.resubmitClaimDetails.damageLocations].text : '';
          }
        } else if (Number.parseInt(this.resubmitClaimDetails.damageLocations) == 3) {
          this.claimResubmit.shipmentStatus = this.resubmitClaimDetails.damageLocationDetails;
        }
      } else if (this.resubmitClaimDetails.resubmissionReason === 2) {
        this.claimResubmit.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
        this.claimResubmit.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
        this.claimResubmit.lossDescription = this.formLossDescription();
        if (Number.parseInt(this.resubmitClaimDetails.missingLocations) < 3) {
          if (this.resubmitFormGroup.value.missingLocations != '') {
            //this.locationDisplay = this.whatHappenedFormGroup.value.missingLocations ? this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text : '';
            this.claimResubmit.shipmentStatus = this.resubmitClaimDetails.missingLocations ? this.damagedLocations[this.resubmitClaimDetails.missingLocations].text : '';
          }
        } else if (Number.parseInt(this.resubmitClaimDetails.missingLocations) == 3) {
          this.claimResubmit.shipmentStatus = this.resubmitClaimDetails.missingLocationDetails;
        }
      } else if (this.resubmitClaimDetails.resubmissionReason === 3) {
        this.claimResubmit.lossDescription = this.formLossDescription();
        //this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lateReship ? this.whatHappenedFormGroup.value.lateReship : '';
        //this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lateRefund ? this.whatHappenedFormGroup.value.lateRefund : '';
        this.claimResubmit.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
      }

      this.reOpenClaim.policyNumber = this.claimResubmit.policy.policyNumber;
      this.reOpenClaim.claimNumber = this.claimResubmit.claimNumber;
      this.reOpenClaim.claimPublicId = this.claimResubmit.publicID;
      this.reOpenClaim.customerNote = this.resubmitClaimDetails.reopenNotes;
      this.reOpenClaim.reason = "newinfo";
      /********************************************/
      let dialogRef: any;
      this.claimService.reopenClaim(this.reOpenClaim).subscribe(
        reopenClaimResponse => {
          //this.currentStep = 1;
          /********************************************/
          if (reopenClaimResponse.code === "500" || reopenClaimResponse.code === "200") {
            this.setError("Reopen");
          }
          else {
            this.claimService.saveClaim(this.claimResubmit).subscribe(
              saveClaimResponse => {
                if (saveClaimResponse.code === "500" || saveClaimResponse.code === "200") {
                  this.setError("Save Claim");
                }
                else {
                  this.submittedClaimNumber = this.claimResubmit.claimNumber;
                  this.currentStep = 1;
                }
              }, error => {
                this.continuing = false;
                this.setError(error);
              }
            );
          }
          /********************************************/
        }, errorReopen => { }
      );
      /********************************************/

    } else {
      this.validateAllFormFields(this.resubmitFormGroup);
      this.errorNotification = true;
      this.scroller.scrollToAnchor("targetReopen");
    }
  }

  setError(operation: string) {
    this.reopenMessage.title = "Oops, something went wrong.";
    this.reopenMessage.content = "Please try again momentarily. If the issue persists, please message your adjuster for assistance.";
    this.reopenMessage.operation = operation;
    this.openErrorDialog(this.reopenMessage);
  }

  openErrorDialog(reopenMessage: ReOpenMessage) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '515px';
    config.data = reopenMessage;
    this.dialogRef = this.dialog
      .open(ReopenMessageComponent, config)
      .afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.cancelReopen();
        }
      });
  }


  exitToDashboard(event) {
    this.router.navigate(['/dashboard']);
  }
  fileNewClaim(event) {
    this.router.navigate(['/claims/new']);
  }
  //Handles the "Other" text field in damage and missing flows
  public toggleOther(type: boolean) {
    this.other = false;
    if (type) {
      this.other = true;
    }
  }

  onReasonChange() {

    this.other = false;
    if (this.resubmitClaimDetails.resubmissionReason === 1) {
      this.damage = true;
      this.missing = false;
      this.late = false;
    }
    else {
      this.damage = false;
    }

    if (this.resubmitClaimDetails.resubmissionReason === 2) {
      this.missing = true;
      this.damage = false;
      this.late = false;
    }
    else {
      this.missing = false;
    }

    if (this.resubmitClaimDetails.resubmissionReason === 0) {

      if (this.flex) {
        this.late = false;
      }
      else
        this.late = true;

      this.missing = false;
      this.damage = false;
    }


    if (this.resubmitClaimDetails.resubmissionReason === 3) {
      this.late = true;
      this.missing = false;
      this.damage = false;
    }

  }

  ngAfterViewInit() {
    if (this.isMessage) {
      this.scroller.scrollToAnchor("targetMessages");
    }
  }

  reloadPage() {
    window.location.reload();
  }

  ngOnInit() {

    this.userDetails = this.userService.getUserInfo();

    this.resubmitClaimDetails = new ResubmitClaim();
    this.resubmitClaimDetails.damageDescription = "";
    this.resubmitClaimDetails.damageDate = "";
    this.resubmitClaimDetails.damageRepairable = "";
    this.resubmitClaimDetails.damageLocations = "";
    this.resubmitClaimDetails.damageLocationDetails = "";
    this.resubmitClaimDetails.missingDescription = "";
    this.resubmitClaimDetails.missingDate = "";
    this.resubmitClaimDetails.missingLocations = "";
    this.resubmitClaimDetails.missingLocationDetails = "";
    this.resubmitClaimDetails.lateCare = "";
    this.resubmitClaimDetails.lateReship = "";
    this.resubmitClaimDetails.lateRefund = "";

    this.claimDetailsTitle = this.translateService.instant('claims.edit.review.reviewAndSubmit.reviewList.claimDetail');

    //Form groups
    this.resubmitFormGroup = this.formBuilder.group({
      merchandiseAmount: ['', Validators.compose([Validators.required])],
      resubmissionReason: ['', Validators.compose([Validators.required])],
      shippingAmount: ['', Validators.compose([Validators.required])],
      reopenNotes: ['', Validators.maxLength(250)],
      claimFileDate: ['',],
      damageDescription: ['', Validators.maxLength(800)],
      damagemissingDate: ['',],
      damageRepairable: ['',],
      damageLocations: ['',],
      damageLocationDetails: ['',],
      missingDescription: ['', Validators.maxLength(800)],
      missingDate: ['',],
      missingLocations: ['',],
      missingLocationDetails: ['', Validators.maxLength(35)],
      lateCare: ['',],
      lateReship: ['',],
      lateRefund: ['',],
      return: ['',],
      originalTrackingNumber: [''],
      merchandiseDescription: ['', Validators.compose([Validators.required, Validators.maxLength(250)])]
    });

    if (this.selectedClaimNumber) {

      this.claimService.getClaimDetails(this.selectedClaimNumber, this.userService.getUserInfo().policyNumber).subscribe(
        data => {
          if (data) {

            if (this.policyType == 'transactional_cargo' || this.policyType == 'traditional_cargo') {
              this.cargo = true;
            } else if (this.policyType == 'transactional_parcel') {
              this.flex = true;
            }

            this.claimResubmit.claimNumber = this.selectedClaimNumber;
            this.claimResubmit.publicID = data.publicID;
            this.claimResubmit.lossType = data.lossType;
            this.claimResubmit.lossCause = data.lossCause;
            this.claimResubmit.shippingDetailsDTO = data.shippingDetailsDTO;
            this.claimResubmit.mainContact = data.mainContact;
            this.claimResubmit.payeeId = data.payeeID;
            this.claimResubmit.productCategory_Ext = data.productCategory_Ext;
            this.claimResubmit.upsmerchandise_Ext = "Description of merchandise in detail = " + data.shippingDetailsDTO.merchandiseDescription;

            try {
              this.lossDescription = JSON.parse(data.description);
            }
            catch (e) {
              this.lossDescription = null;
            }

            this.resubmitClaimDetails.claimNumber = this.selectedClaimNumber;
            this.claim.claimNumber = this.selectedClaimNumber;
            this.claim.tracking = data.shippingDetailsDTO.trackingNumber ? data.shippingDetailsDTO.trackingNumber : this.translateService.instant('claimsDetail.notProvided');
            this.claim.shipDate = data.shippingDetailsDTO.shipDate ? data.shippingDetailsDTO.shipDate : this.translateService.instant('claimsDetail.notProvided');

            //this.resubmitClaimDetails.shippingAmount = parseFloat(this.claimService.formatAmount(data.shippingDetailsDTO.verifiedShippingCharge, false));
            this.resubmitClaimDetails.shippingAmount = data.shippingDetailsDTO.verifiedShippingCharge;
            //this.resubmitClaimDetails.merchandiseAmount = parseFloat(this.claimService.formatAmount(data.shippingDetailsDTO.merchandiseValue, false));
            this.resubmitClaimDetails.merchandiseAmount = data.shippingDetailsDTO.merchandiseValue;


            this.resubmitClaimDetails.merchandiseDescription = data.shippingDetailsDTO.merchandiseDescription;

            if (data.lossCause == "loss_Ext") {
              this.claim.reason = this.translateService.instant('claimsDetail.loss');
              this.resubmitClaimDetails.resubmissionReason = 0;
              this.late = true;
            } else if (data.lossCause == "damage_Ext") {
              this.claim.reason = this.translateService.instant('claimsDetail.damage');
              this.resubmitClaimDetails.resubmissionReason = 1;
              this.damage = true;
            } else if (data.lossCause == "fallout_Ext") {
              this.claim.reason = this.translateService.instant('claimsDetail.missingContents');
              this.resubmitClaimDetails.resubmissionReason = 2;
              this.missing = true;
            } else if (data.lossCause == "delay") {
              this.claim.reason = this.translateService.instant('claimsDetail.lateDelivery');
              this.resubmitClaimDetails.resubmissionReason = 3;
              this.late = true;
            } else {
              this.claim.reason = this.translateService.instant('claimsDetail.notProvided');
              this.resubmitClaimDetails.resubmissionReason = -1;
            }
            this.claim.checkNumber = data.checks.length == 0 ? this.translateService.instant('claimsDetail.notProvided') : data.checks[data.checks.length - 1].checkNumber;
            this.claim.checkIssueDate = data.checks.length == 0 ? "null" : data.checks[data.checks.length - 1].issueDate;
            let sliceTrack = data.shippingDetailsDTO.trackingNumber.slice(0, 2);
            if (sliceTrack.toUpperCase() == '1Z') {
              this.claim.upsAccountNumber = data.shippingDetailsDTO.trackingNumber.slice(2, 8).toUpperCase();
            }
            else { this.claim.upsAccountNumber = this.translateService.instant('claimsDetail.notProvided'); }
            this.claim.reference = data.shippingDetailsDTO.referenceNumber ? data.shippingDetailsDTO.referenceNumber : this.translateService.instant('claimsDetail.notProvided');
            this.claim.shipperCompany = data.shippingDetailsDTO.senderDetails?.companyName ? data.shippingDetailsDTO.senderDetails.companyName : this.translateService.instant('claimsDetail.notProvided');
            this.claim.shipperAddress = data.shippingDetailsDTO.senderDetails?.address1 ? data.shippingDetailsDTO.senderDetails.address1 : this.translateService.instant('claimsDetail.notProvided');
            this.claim.shipperCity = data.shippingDetailsDTO.senderDetails?.city ? `${data.shippingDetailsDTO.senderDetails.city}` : this.translateService.instant('claimsDetail.notProvided');

            //this.claim.shipperState = data.shippingDetailsDTO.senderDetails?.state ? ` ${data.shippingDetailsDTO.senderDetails.state}` : "";

            var shipperState = this.stateList.find(x => x.key === data.shippingDetailsDTO.senderDetails?.state);
            if (shipperState) {
              this.claim.shipperState = shipperState.value;
            }

            //this.claim.shipperCountry = data.shippingDetailsDTO.senderDetails?.country ? data.shippingDetailsDTO.senderDetails.country : this.translateService.instant('claimsDetail.notProvided');
            var shipperCountry = this.countryList.find(x => x.key === data.shippingDetailsDTO.senderDetails?.country);
            if (shipperCountry) {
              this.claim.shipperCountry = shipperCountry.value;
            }

            this.claim.recipientCompany = data.shippingDetailsDTO.receiverDetails?.companyName ? data.shippingDetailsDTO.receiverDetails.companyName : this.translateService.instant('claimsDetail.notProvided');
            this.claim.recipientAddress = data.shippingDetailsDTO.receiverDetails?.address1 ? data.shippingDetailsDTO.receiverDetails.address1 : this.translateService.instant('claimsDetail.notProvided');
            this.claim.recipientCity = data.shippingDetailsDTO.receiverDetails?.city ? `${data.shippingDetailsDTO.receiverDetails.city}` : this.translateService.instant('claimsDetail.notProvided');

            var recipientState = this.stateList.find(x => x.key === data.shippingDetailsDTO.receiverDetails?.state);
            if (recipientState) {
              this.claim.recipientState = recipientState.value;
            }

            var recipientCountry = this.countryList.find(x => x.key === data.shippingDetailsDTO.receiverDetails?.country);
            if (recipientCountry) {
              this.claim.recipientCountry = recipientCountry.value;
            }

            //this.claim.recipientState = this.stateList.find(x => x.key == data.shippingDetailsDTO.receiverDetails?.state) ? ` ${this.stateList.find(x => x.key == data.shippingDetailsDTO.receiverDetails?.state).value}` : ` ${data.shippingDetailsDTO.receiverDetails?.state}`;
            //this.claim.recipientCountry = data.shippingDetailsDTO.receiverDetails?.country ? data.shippingDetailsDTO.receiverDetails.country : this.translateService.instant('claimsDetail.notProvided');

            this.claim.fName = data.mainContact.firstName ? data.mainContact.firstName : this.translateService.instant('claimsDetail.notProvided');
            this.claim.lName = data.mainContact.lastName ? data.mainContact.lastName : this.translateService.instant('claimsDetail.notProvided');
            this.claim.email = data.mainContact.emailAddress1 ? data.mainContact.emailAddress1 : this.translateService.instant('claimsDetail.notProvided');
            this.claim.phoneNumber = data.mainContact.workNumber ? data.mainContact.workNumber : this.translateService.instant('claimsDetail.notProvided');
            this.claim.description = data.shippingDetailsDTO.merchandiseDescription ? data.shippingDetailsDTO.merchandiseDescription : this.translateService.instant('claimsDetail.notProvided');
            this.claim.fileDate = data.reportedDate ? data.reportedDate : this.translateService.instant('claimsDetail.notProvided');
            this.claim.adjusterName = data.adjuster.displayName ? data.adjuster.displayName : this.translateService.instant('claimsDetail.notProvided');
            this.claim.adjusterEmail = data.adjuster.email ? data.adjuster.email : this.translateService.instant('claimsDetail.notProvided');
            this.claim.adjusterPhone = data.adjuster.phoneNumber ? data.adjuster.phoneNumber : this.translateService.instant('claimsDetail.notProvided');
            this.claim.productCategory = data.productCategory_Ext ? data.productCategory_Ext : this.translateService.instant('claimsDetail.notProvided');
            this.claim.claimState = data.claimState ? data.claimState : this.translateService.instant('claimsDetail.notProvided');
            this.claim.claimSubStatus = data.claimSubStatus ? data.claimSubStatus : this.translateService.instant('claimsDetail.notProvided');

            if (data.reportedDate) {
              this.claimFileDate = this.getUtcDate(data.reportedDate);
            }

            this.claim.customerDisplayStatus = data.customerDisplayStatus == 'Closed' && data.closedOutCome == 'incomplete_documentation_Ext' ? 'Documents Pending' : data.customerDisplayStatus, //If there is a CLOSED claim with INCOMPLETE_DOCUMENTATION_EXT, then we remap it to DOCUMENTS PENDING
              //Handling all language status below
              this.claim.customerDisplayStatusTitle = data.customerDisplayStatus == 'Claim Received' ? this.translateService.instant('metadata.claimStatusMapping.claim_received') :
                (data.customerDisplayStatus == 'Closed' && data.closedOutCome == 'incomplete_documentation_Ext') ? this.translateService.instant('metadata.claimStatusMapping.incomplete_documentation_Ext') :
                  data.customerDisplayStatus == 'Closed' ? this.translateService.instant('metadata.claimStatusMapping.closed') :
                    data.customerDisplayStatus == 'Paid' ? this.translateService.instant('metadata.claimStatusMapping.paid') :
                      data.customerDisplayStatus == 'Withdrawn' ? this.translateService.instant('metadata.claimStatusMapping.withdrawn_Ext') :
                        data.customerDisplayStatus == 'Submitted for Payment' ? this.translateService.instant('metadata.claimStatusMapping.submitted_for_payment') :
                          data.customerDisplayStatus == 'Incomplete Submission' ? this.translateService.instant('metadata.claimStatusMapping.incomplete_submission') :
                            data.customerDisplayStatus == 'Investigation Pending' ? this.translateService.instant('metadata.claimStatusMapping.investigation_Pending') :
                              data.customerDisplayStatus == 'Denied' ? this.translateService.instant('metadata.claimStatusMapping.denied_Ext') :
                                data.customerDisplayStatus == 'Documents Pending' ? this.translateService.instant('metadata.claimStatusMapping.incomplete_documentation_Ext') : data.customerDisplayStatus,


              this.claim.claimedAmount = data.claimAmount_Ext ? data.claimAmount_Ext.replace(' usd', '').replace(' cad', '').replace(' gbp', '').replace(' eur', '') : 0.0;
            this.claim.totalPaymentAmount = data.totalPayments.amount ? data.totalPayments.amount : 0;
            this.claim.currency = data.totalPayments.currency ? data.totalPayments.currency : 'usd';


            if (data.customerNotes !== undefined && data.customerNotes !== null) {
              this.claim.customerNotes = data.customerNotes;
              this.isNotePresent = true;
              this.resubmitClaimDetails.reopenNotes = data.customerNotes;
            }
            else {
              this.notesNotPresent = this.translateService.instant('claimsDetail.notProvided');
              this.isNotePresent = false;
              this.claim.customerNotes = "";
              this.resubmitClaimDetails.reopenNotes = "";
            }

            //this.claim.customerNotes = data.customerNotes ? data.customerNotes : this.translateService.instant('claimsDetail.notProvided');

            this.claim.idrdocList = data.idrdocList ? data.idrdocList : null;
            this.claim.closedOutCome = data.closedOutCome;
            this.claim.payeeID = data.payeeID;
            this.claim.quantity = data.quantity;
            this.currencyType = data.claimAmount_Ext.split(' ')[1];
            this.claim.documents = (data.documents && data.documents.length > 0) ? data.documents : this.translateService.instant('claimsDetail.notProvided');
            if (this.claim.documents != 'Not Provided') {
              for (let i = 0; i < Object.keys(this.claim.documents).length; i++) {
                if (this.claim.documents[i].documentType == 'letter_received' || this.claim.documents[i].documentType == 'onl_document_Ext') {
                  this.uuDocuments.push(this.claim.documents[i]);
                } else {
                  this.sgDocuments.push(this.claim.documents[i]);
                }
              }
            }
            if (this.claim.customerDisplayStatus == 'Denied' || (this.claim.customerDisplayStatus && this.claim.customerDisplayStatus.includes('Closed'))) {
              this.statusDetails = this.translateService.instant(`metadata.claimStatusMapping.${data.closedOutCome}`); //undefined if no status details are found. only need to check for denied or closed claims.
            }
            // for (let i = 0; i < data.claimContacts.length; i++) {
            //   if (data.claimContacts[i].contactRoles[0] === "transportation_carrier_Ext") {
            //     this.claim.carrier = data.claimContacts[i].contactDTO.displayName;
            //   }
            // }
            if (this.claim.customerDisplayStatus == 'Claim Received' || this.claim.customerDisplayStatus == 'Documents Pending' || this.claim.customerDisplayStatus == 'Investigation Pending') {
              this.isWithdrawable = true;
            }
            if (this.claim.customerDisplayStatus == 'Withdrawn') {
              this.isWithdrawn = true;
            }
            for (let claimContactsData of data.claimContacts) {
              if (claimContactsData.contactRoles[0] === "transportation_carrier_Ext") {
                this.claim.carrier = claimContactsData.contactDTO.displayName;
              }
              if (claimContactsData.contactRoles[0] === "receiver_Ext") {
                this.claim.recipientZipcode = claimContactsData.contactDTO?.primaryAddress?.postalCode;
              }
              if (claimContactsData.contactRoles[0] === "shipper_Ext") {
                this.claim.shipperZipcode = claimContactsData.contactDTO?.primaryAddress?.postalCode;
              }
              if (claimContactsData.contactRoles.includes("policy_payee_Ext") || claimContactsData.contactRoles.includes("checkpayee")) {
                this.claim.payeeDetails = {
                  fullName: claimContactsData.contactDTO.displayName,
                  email: claimContactsData.contactDTO.emailAddress1,
                  phone: claimContactsData.contactDTO.workNumber,
                  addressLine1: claimContactsData.contactDTO.primaryAddress?.addressLine1,
                  addressLine2: claimContactsData.contactDTO.primaryAddress?.addressLine2,
                  city: claimContactsData.contactDTO.primaryAddress?.city,
                  state: this.stateList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.state)?.value ? this.stateList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.state).key : claimContactsData.contactDTO.primaryAddress?.state,
                  country: this.countryList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.country)?.value ? this.countryList.find(x => x.key == claimContactsData.contactDTO.primaryAddress?.country).value : claimContactsData.contactDTO.primaryAddress?.country,
                  zip: claimContactsData.contactDTO.primaryAddress?.postalCode,
                  walletIndicator: ""
                }
              }
            }

            if (this.claim.payeeID && !this.claim.payeeDetails && !this.isUsaUser && !this.isCanadaUser) {
              let request = {
                payeeID: this.claim.payeeID,
                policyNumber: this.userService.getUserInfo().policyNumber
              }
              this.claimService.getPayeeDetails(request).subscribe(
                data => {
                  if (data.data.paymentMethod == "ACH") {
                    this.claim.ach = "**" + data.data.accountNumber;
                  } else if (data.data.paymentMethod == "Zelle") {
                    this.claim.ach = this.translateService.instant('payee.table.zelle');
                  } else {
                    this.claim.ach = this.translateService.instant('payee.table.check');
                  }

                  this.claim.payeeDetails = {
                    fullName: data.data.payeeName,
                    email: data.data.email,
                    phone: data.data.phoneNumber,
                    addressLine1: data.data.addressLine1,
                    addressLine2: data.data.addressLine2,
                    city: data.data.city,
                    state: data.data.state,
                    zip: data.data.zip,
                    walletIndicator: ""
                  }
                }, error => {
                  this.claim.ach = this.translateService.instant('payee.table.check');
                }
              );
            }
            if ((this.claim.payeeID) && (this.isUsaUser || this.isCanadaUser)) {
              // If the payeeID is actually a walletID (it will start with W), we don't want to call getPayeeDetails. Show 'Pay to UPS Wallet' instead
              if (this.claim.payeeID.startsWith('W') || this.claim.payeeID.startsWith('w')) {
                this.claim.payeeDetails = {};
                this.claim.payeeDetails.walletIndicator = 'wallet';
              } else {
                let request = {
                  payeeID: this.claim.payeeID,
                  policyNumber: this.userService.getUserInfo().policyNumber
                }
                this.claimService.getPayeeDetails(request).subscribe(
                  data => {
                    if (data.data.paymentMethod == "ACH") {
                      this.claim.ach = "**" + data.data.accountNumber;
                    } else if (data.data.paymentMethod == "Zelle") {
                      this.claim.ach = this.translateService.instant('payee.table.zelle');
                    } else {
                      this.claim.ach = this.translateService.instant('payee.table.check');
                    }
  
                    this.claim.payeeDetails = {
                      fullName: data.data.payeeName,
                      email: data.data.email,
                      phone: data.data.phoneNumber,
                      addressLine1: data.data.addressLine1,
                      addressLine2: data.data.addressLine2,
                      city: data.data.city,
                      state: data.data.state,
                      zip: data.data.zip,
                      walletIndicator: ""
                    }
                  }, error => {
                    this.claim.ach = this.translateService.instant('payee.table.check');
                  }
                );
              }
            } else {
              this.claim.ach = this.translateService.instant('payee.table.check');
            }
            if (this.claim.carrier) {
              //Do nothing, already good to go
            } else {
              this.claim.carrier = this.translateService.instant('claimsDetail.notProvided');
            }
          }
        }, error => { }
      );
    }
  }

  export(type: string) {
    if (this.claim.tracking) {
      this.excelService.claimDetailToPDF(this.claim, `${this.claim.claimNumber} ${this.FILENAME}`, type);
    }
  }

  //Downloads documents from claim detail
  download(doc) {
    this.claimService.downloadDocument(doc.publicID.split(":")[1], this.policy.policyNumber).subscribe(
      data => {
        let file = new Blob([data], { type: doc.mimeType });
        FileSaver.saveAs(file, doc.name);
      }, error => { }
    );
  }

  persistSearch() {
    if (sessionStorage.getItem('retainState')) {
      sessionStorage.setItem('isPostBack', 'true');
    }
    else {
      sessionStorage.setItem('isPostBack', 'false');
    }
  }

  withdrawPopup() {
    let dialogRef = this.dialog.open(WithdrawPopupComponent);
    // dialogRef.afterClosed().subscribe();
    dialogRef.componentInstance.refreshWithdrawStatus.subscribe(
      isWithdrawn => {
        if (isWithdrawn) { //if withdraw button is clicked
          this.claim.customerDisplayStatus = 'Withdrawn'; //TEMPORARY HARDCODE SINCE API DOESNT CURRENTLY WORK AND CHANGE THE CLAIM TO "WITHDRAWN"
          // ^ API should be able to do this, I think
          this.claim.customerDisplayStatusTitle = this.translateService.instant('metadata.claimStatusMapping.withdrawn_Ext');
          this.isWithdrawn = true;
          this.withdrawnClaim.userPolicyNumber = this.userService.getUserInfo().policyNumber;
          this.withdrawnClaim.userClaimNumber = this.claim.claimNumber;
          this.claimService.withdrawClaim(this.withdrawnClaim).subscribe(
            res => {
              if (res) {
                // console.log("API response", res);
              }
              else {
                // console.log("error", res)
              }
            },
            err => {
              // console.log("error2");
            }
          );
        }
      }
    );
  }

  dialogRef: any;
  public openReopenConfirmation() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '515px';
    config.data = "";
    this.dialogRef = this.dialog
      .open(ReopenConfirmationComponent, config)
      .afterClosed().subscribe(result => {
        if (result === "Reopen") {
          this.populateReOpenFields();
        }
      });
  }

  populateReOpenFields() {

    this.isReopen = true;
    this.claimDetailsTitle = this.translateService.instant('claims.edit.review.reviewAndSubmit.reviewList.resubmitClaimDetail');

    if (this.lossDescription) {

      var lossReason = this.lossDescription.reason;
      if (lossReason !== null || lossReason !== undefined) {

        if (lossReason == 1) {
          this.damage = true;
          this.late = false;
          this.missing = false;

          this.resubmitClaimDetails.resubmissionReason = 1;
          this.resubmitClaimDetails.damageDescription = this.lossDescription.damageDescription;

          if (this.lossDescription.damageDate !== undefined && this.lossDescription.damageDate.length > 3) {
            var damageDate = this.lossDescription.damageDate.substring(0, this.lossDescription.damageDate.length - 1);
            this.resubmitClaimDetails.damageDate = damageDate + "Z";
          }

          if (this.lossDescription.repairable !== undefined) {
            this.resubmitClaimDetails.damageRepairable = this.lossDescription.repairable;
          }
        }

        //Reason: Missing Content
        if (lossReason == 2) {
          this.late = false;
          this.damage = false;
          this.missing = true;
          this.resubmitClaimDetails.resubmissionReason = 2;
          this.resubmitClaimDetails.missingDescription = this.lossDescription.missingDescription;

          var missingDate = this.lossDescription.missingDate.substring(0, this.lossDescription.missingDate.length - 1);
          if (missingDate && missingDate.length > 3) {
            this.resubmitClaimDetails.missingDate = missingDate + "Z";
          }
        }

        //Reason: Loss Or Late Delivery
        if (lossReason == 0) {

          if (lossReason == 3) {
            this.resubmitClaimDetails.resubmissionReason = 3;
          }
          else
            this.resubmitClaimDetails.resubmissionReason = 0;

          if (this.flex) {
            this.late = false;
            this.damage = false;
            this.missing = false;
          }

          if (this.cargo) {
            this.late = true;
            this.damage = false;
            this.missing = false;

            this.resubmitClaimDetails.lateCare = this.lossDescription.care;
            if (this.resubmitClaimDetails.lateCare === "0") {
              this.resubmitClaimDetails.lateReship = this.lossDescription.careDetails;
            }
            else
              this.resubmitClaimDetails.lateRefund = this.lossDescription.careDetails;
          }
        }


        if (lossReason == 3) {

          this.late = true;
          this.damage = false;
          this.missing = false;

          if (lossReason == 3) {
            this.resubmitClaimDetails.resubmissionReason = 3;
          }
          else
            this.resubmitClaimDetails.resubmissionReason = 0;

          this.resubmitClaimDetails.lateCare = this.lossDescription.care;
          if (this.resubmitClaimDetails.lateCare === "0") {
            this.resubmitClaimDetails.lateReship = this.lossDescription.careDetails;
          }
          else
            this.resubmitClaimDetails.lateRefund = this.lossDescription.careDetails;
        }

        if (this.lossDescription.packageLocation !== undefined) {
          if (this.lossDescription.packageLocation === "3") {
            this.other = true;
          }
          if (this.resubmitClaimDetails.resubmissionReason === 1) {
            this.resubmitClaimDetails.damageLocations = this.lossDescription.packageLocation
            this.resubmitClaimDetails.damageLocationDetails = this.lossDescription.packageLocationDetails;
          }

          if (this.resubmitClaimDetails.resubmissionReason === 2) {
            this.resubmitClaimDetails.missingLocations = this.lossDescription.packageLocation
            this.resubmitClaimDetails.missingLocationDetails = this.lossDescription.packageLocationDetails;
          }
        }



        if (this.lossDescription.return !== undefined && this.lossDescription.return === "yes") {
          if (this.lossDescription.originalTrackingNumber !== undefined) {
            //this.isReturnShipment = true;
            this.resubmitClaimDetails.isReturnShipment = "yes"
            this.resubmitClaimDetails.originalTrackNumber = this.lossDescription.originalTrackingNumber;
          }
        }
        else {
          //this.isReturnShipment = false;
          this.resubmitClaimDetails.isReturnShipment = "no"
          this.resubmitClaimDetails.originalTrackNumber = "";
        }
      }

    }

    this.scroller.scrollToAnchor("targetReopen");
  }

  getUtcDate(stringDate: string) {
    var date = new Date(stringDate);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date.toLocaleDateString();
  }

  clearDocumentErrors() {
    this.fileTooBig = false;
    this.fileNameError = false;
    this.fileTypeError = false;
    this.systemError = false;
  }

  uploadFile(event) { //event is the fileList
    let file = event[0];
    let formData: FormData = new FormData();
    // this.fileTooBig = false;

    this.clearDocumentErrors();

    if (file.name.length > 80) {
      this.fileNameError = true;
      return;
    }
    /* let fileNameParts = file.name.split('.');
    if (fileNameParts.length <= 1 || ALLOWED_DOC_TYPES.indexOf(fileNameParts[fileNameParts.length - 1].toLowerCase()) < 0) {
      this.fileTypeError = true;
      return;
    } */
    if (file.size > 7340032) {
      this.fileTooBig = true;
    }
    else {
      this.showFileProgress = true;
      this.listOfFiles.push({
        "name": file.name,
        "processing": true,
        "publicID": "",
        "error": false
      });
      this.claimService.generateUploadToken().subscribe(
        data => {
          let docRequest = {
            "sessionID": data,
            "claimNumber": this.claim.claimNumber,
            "name": file.name,
            "docUID": "001",
            "documentType": "letter_received",
            "securityType": "unrestricted",
            "status": "approved",
            "postFnolDoc": "true",
            "mimeType": ""
          }

          if (file.name.endsWith('.msg')) {
            docRequest.mimeType = "application/vnd.ms-outlook"
          } else {
            docRequest.mimeType = file.type;
          }

          formData.append('documentUploadRequest', JSON.stringify(docRequest));
          formData.append('file', file, file.name);

          this.claimService.uploadDocument(formData).subscribe(
            data1 => {
              if (data1.publicID) {
                let i = this.listOfFiles.findIndex(x => x.name == file.name);
                this.listOfFiles[i].processing = false;
                this.listOfFiles[i].publicID = data1.publicID;
              }
              else {
                this.systemError = true;
                let i = this.listOfFiles.findIndex(x => x.name == file.name);
                this.listOfFiles[i].processing = false;
                this.listOfFiles[i].error = true;
              }
            },
            error => {
              this.systemError = true;
              let i = this.listOfFiles.findIndex(x => x.name == file.name);
              this.listOfFiles[i].processing = false;
              this.listOfFiles[i].error = true;
            }
          );
        },
        err => {
          this.systemError = true;
          let i = this.listOfFiles.findIndex(x => x.name == file.name);
          this.listOfFiles[i].processing = false;
          this.listOfFiles[i].error = true;
        }
      );
    }
  }

  remove(file) {
    this.listOfFiles[this.listOfFiles.findIndex(x => x.name == file.name)].processing = true;
    this.claimService.removeDocument(file).subscribe(
      data => {
        this.listOfFiles[this.listOfFiles.findIndex(x => x.name == file.name)].processing = false;
        this.listOfFiles.splice(this.listOfFiles.findIndex(x => x.name == file.name), 1);
      }, error => {
        this.systemError = true;
        let i = this.listOfFiles.findIndex(x => x.name == file.name);
        this.listOfFiles[i].processing = false;
        this.listOfFiles[i].error = true;
      }
    )
  }

  determineMessaging() {
    if (!this.userService.getUserPermissions()?.isClaimView) {
      this.canMessageAdjuster = true;
    } else {
      this.canMessageAdjuster = false;
    }
  }

}
