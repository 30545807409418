<div class="claim-section">
  <div class="logohead d-flex justify-content-between align-items-center"
    [ngClass]="{'my-choice': flowType=='my-choice', 'tupss': flowType=='tupss', 'customized-claims-portal': flowType=='customized-claims-portal', 'ajg': flowType=='ajg', 'inxpress': flowType=='inxpress', 'upshealthcare': flowType=='upshealthcare' , 'freightcenterinc': flowType=='freightcenterinc', 'pirateship': flowType === 'pirateship'}">
    <img *ngIf="flowType == 'tupss'" class="logo" src="/assets/images/the_ups_store_logo.svg"
      alt="{{ 'billing.method.altUps' | translate }}">
    <img *ngIf="flowType == 'my-choice'" class="logo" src="/assets/images/insureshield-shipping-protection-logo.png">
    <div class="ccp-header"
      *ngIf="flowType == 'customized-claims-portal' || flowType == 'ajg' || flowType == 'inxpress'">
      <div class="d-flex flex-row main-menu ccp" *ngIf="!isMobile">
        <img class="ccp-logo" src='{{currentTheme.logoDesktop}}'>
        <div class="vertical-divider" *ngIf="currentTheme.isChannelPartnerLogo"></div>
        <img class="ccp-logo pic2" src='{{currentTheme.channelPartnerLogo}}' *ngIf="currentTheme.isChannelPartnerLogo">
        <ul class="navigation-items ccp">
          <!-- since we don't have pages for FAQs and contact us yet, the claims tab is hardcoded to be active for now (once we have routing for FAQ/contact us, change this) -->
          <li class="navigation-item ccp" (click)="setHighlghter('claims')"
            [class.ccp-active]="tabData.isClaimTabActive" *ngIf="!isGuestUser">
            <a class="ccp" routerLink="/{{channelPartner}}">Claims</a>
          </li>
          <li class="navigation-item ccp" (click)="setHighlghter('faq')" [class.ccp-active]="tabData.isFaqTabActive">
            <a class="ccp" href="#" routerLink="/{{channelPartner}}/faq">FAQs</a>
          </li>
        </ul>
      </div>
      <div class="d-flex" *ngIf="isMobile">
        <div class="d-flex flex-row">
          <img class="ccp-logo mobile" src='{{currentTheme.logoMobile}}'>
          <div class="vertical-divider" *ngIf="currentTheme.isChannelPartnerLogo"></div>
          <img class="ccp-logo mobile pic2" src='{{currentTheme.channelPartnerLogoMobile}}'
            *ngIf="currentTheme.isChannelPartnerLogo">
        </div>
        <div class="ml-auto">
          <mat-icon class="menu-format" (click)="showSidebar = !showSidebar">menu</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="flowType === 'pirateship' " [ngClass]="{'pirateShipHeader': true}">
      <div class="wrapper" *ngIf="!isPirateShipDetails && !isPirateError && !isPirateShipSuccess">
        <div class="text">
          <div class="header" *ngIf="!isPirateShipFile && !isPirateShipReview">{{ 'claims.claimMenus.pirateStartClaim' |
            translate }}</div>
          <div class="header" *ngIf="isPirateShipFile">{{ 'claims.claimMenus.pirateStartClaim2' | translate }}</div>
          <div class="header" *ngIf="!isPirateShipFile && isPirateShipReview">{{ 'claims.claimMenus.pirateStartClaim3' |
            translate }}</div>
          <!-- <div class="header"
            *ngIf="isPirateShipSuccess && !isPirateShipFile && !isPirateShipReview && !isPirateShipLanding">{{
            'claims.claimMenus.pirateStartClaim4' | translate }}</div>
          <div class="header" *ngIf="isPirateError && !isPirateShipLanding">{{
            'claims.claimMenus.pirateStartClaim5' | translate }}</div> -->
          <div class="d-flex flex-column" style="gap: 10px;">
            <div class="subtitle" *ngIf="!isPirateShipFile && !isPirateShipReview">{{ 'claims.claimMenus.pirateSubtitle'
              | translate }}</div>
            <div class="subtitle" *ngIf="!isPirateShipFile && !isPirateShipReview">{{
              'claims.claimMenus.pirateSubtitle2' | translate }}</div>
            <div class="subtitle" *ngIf="isPirateShipFile && !isPirateShipReview" style="width: 75%;">{{
              'claims.claimMenus.pirateSubtitle3' | translate }}</div>
            <div class="subtitle" *ngIf="!isPirateShipFile && isPirateShipReview">{{ 'claims.claimMenus.pirateSubtitle4'
              | translate }}</div>
            <div class="subtitle" *ngIf="!isPirateShipFile && isPirateShipReview">{{ 'claims.claimMenus.pirateSubtitle5'
              | translate }}</div>
            <!-- <div class="subtitle"
              *ngIf="isPirateShipSuccess && !isPirateShipFile && !isPirateShipReview && !isPirateShipLanding">{{
              'claims.claimMenus.pirateSubtitle6' | translate }}</div>
            <div class="subtitle" *ngIf="isPirateError && !isPirateShipLanding">{{
              'claims.claimMenus.pirateSubtitle7' | translate }}</div> -->
          </div>
        </div>
        <img class="pirateShipStep1" *ngIf="!isPirateShipFile && !isPirateShipReview && !isPirateShipSuccess"
          src="../../../assets/images/pirateShipStep1.svg">
        <img class="pirateShipStep2" *ngIf="isPirateShipFile" src="../../../assets/images/pirateShipStep2.png">
        <img class="pirateShipStep2" *ngIf="!isPirateShipFile && isPirateShipReview"
          src="../../../assets/images/pirateShipStep3.png">
        <!-- <img class="pirateShipStep2"
          *ngIf="isPirateShipSuccess && !isPirateShipFile && !isPirateShipReview && !isPirateShipLanding"
          src="../../../assets/images/pirateShipStep4.png"
          style="align-self: center !important; width: 218px; height: 207px; flex-shrink: 0;">
        <img class="pirateShipStep2" *ngIf="isPirateError && !isPirateShipLanding"
          src="../../../assets/images/pirateShipStep6.png"
          style="align-self: center !important; width: 211px; height: 211px; flex-shrink: 0;"> -->
      </div>
      <div class="details-wrapper" *ngIf="isPirateShipDetails && !isPirateError && !isPirateShipSuccess">
        <img src="../../../assets/images/Insurehsield-Logo.png" style="margin-left: 20px;">
      </div>


      <div class="wrapper" *ngIf="isPirateShipSuccess && !isPirateError">
        <div class="text">
          <div class="header"
            *ngIf="isPirateShipSuccess && !isPirateShipFile && !isPirateShipReview && !isPirateShipLanding">{{
            'claims.claimMenus.pirateStartClaim4' | translate }}</div>
          <img class="pirateShipStep2"
            *ngIf="isPirateShipSuccess && !isPirateShipFile && !isPirateShipReview && !isPirateShipLanding"
            src="../../../assets/images/pirateShipStep4.png"
            style="align-self: center !important; width: 218px; height: 207px; flex-shrink: 0;">
          <div class="subtitle"
            *ngIf="isPirateShipSuccess && !isPirateShipFile && !isPirateShipReview && !isPirateShipLanding">{{
            'claims.claimMenus.pirateSubtitle6' | translate }}</div>
        </div>
      </div>
      <div class="wrapper" *ngIf="!isPirateShipSuccess && isPirateError">
        <div class="text">
          <div class="header" *ngIf="isPirateError && !isPirateShipLanding">{{
            'claims.claimMenus.pirateStartClaim5' | translate }}</div>
          <img class="pirateShipStep2" *ngIf="isPirateError && !isPirateShipLanding"
            src="../../../assets/images/pirateShipStep6.png"
            style="align-self: center !important; width: 211px; height: 211px; flex-shrink: 0;">
          <div class="subtitle" *ngIf="isPirateError && !isPirateShipLanding">{{
            'claims.claimMenus.pirateSubtitle7' | translate }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="flowType == 'upshealthcare' || flowType == 'freightcenterinc'">
      <div class="ccp-header">
        <div class="d-flex flex-row main-menu ccp" *ngIf="!isMobile">
          <img class="ccp-logo" src='../../../../assets/images/insureshield-shipping-protection-logo.png'>
          <!-- CCP self service portal -->
          <div class="vertical-divider"></div>
          <img class="ccp-logo pic2" src='{{companyLogo}}'>
          <!-- <img class="ccp-logo pic2" src='{{companyLogo}}' *ngIf="currentTheme.isChannelPartnerLogo">       -->
          <ul class="navigation-items ccp">
            <!-- since we don't have pages for FAQs and contact us yet, the claims tab is hardcoded to be active for now (once we have routing for FAQ/contact us, change this) -->
            <li class="navigation-item ccp" [class.ccp-active]="tabData.isClaimTabActive" *ngIf="!isGuestUser">
              <a class="ccp" routerLink="/{{channelPartner}}">Claims</a>
            </li>
            <li class="navigation-item ccp" [class.ccp-active]="tabData.isFaqTabActive">
              <a class="ccp" href="#" routerLink="/{{channelPartner}}/faq">FAQs</a>
            </li>
          </ul>
        </div>
        <!-- <div class="d-flex" *ngIf="isMobile">
          <div class="d-flex flex-row">
            <img class="ccp-logo mobile" src='{{currentTheme.logoMobile}}'>
            <div class="vertical-divider" *ngIf="currentTheme.isChannelPartnerLogo"></div>
            <img class="ccp-logo mobile pic2" src='{{currentTheme.channelPartnerLogoMobile}}' *ngIf="currentTheme.isChannelPartnerLogo">
          </div>
          <div class="ml-auto">
            <mat-icon class="menu-format" (click)="showSidebar = !showSidebar">menu</mat-icon>
          </div>
        </div> -->
      </div>
    </div>



  </div>
  <!-- Really want to figure out the slide functionality but may be impossible due to the configuration of the components -->
  <!-- <div class="sidebar-slider" [class.sidebar-slide-in]="isCollapsed" *ngIf="isMobile"> -->
  <div class="sidebar-slider" *ngIf="isMobile && showSidebar">
    <div class="sidebar-close" (click)="showSidebar = !showSidebar"><mat-icon>close</mat-icon></div>
    <div class="sidebar-content">
      <a class="sidebar-text" [class.sidebar-active]="true" routerLink="/{{channelPartner}}"
        (click)="showSidebar = !showSidebar">Claims</a>
      <a class="sidebar-text" [class.sidebar-active]="true" routerLink="/{{channelPartner}}/faq"
        (click)="showSidebar = !showSidebar">FAQs</a>
      <!-- <a class="sidebar-text">Contact Us</a> -->
    </div>
  </div>
  <div class="main-menu" *ngIf="flowType == 'tupss'">
    <ul class="navigation-items">
      <li *ngIf="!currentUrl.startsWith('/tupss/faqs')" class="navigation-item">
        <a href="#" (click)="fileClaimClick($event)" [class.active]="subNav || currentUrl.includes('claims')">
          {{ 'app.navigation.claims' | translate }}
        </a>
      </li>
      <li *ngIf="tfcp && showPolicy && !currentUrl.startsWith('/tupss/faqs')" class="navigation-item">
        <a href="#" (click)="policyClick($event)" [class.active]="currentUrl.includes('policy')">
          {{ 'franchiseclaimportal.policy' | translate }}
        </a>
      </li>
      <li class="navigation-item">
        <a href="#" (click)="openFAQs($event)" [class.active]="currentUrl.startsWith('/tupss/faqs')">
          {{ 'app.navigation.faq' | translate }}
        </a>
      </li>
      <li *ngIf="!currentUrl.startsWith('/tupss/faqs')" class="navigation-item">
        <a (click)="openContactUsDialog()">
          <span class="key align-self-center link">{{ 'app.navigation.contactUs' | translate }}</span>
        </a>
      </li>
      <li *ngIf="((tfcp) && (!currentUrl.startsWith('/tupss/faqs'))
      && (currentUrl.startsWith('/tfcp/claims'))) && !isMobile" class="navigation-dropdown">
        <upsc-policy-select></upsc-policy-select>
      </li>
    </ul>
  </div>

  <div [ngClass]="{'tupss-claims-wrapper p-3' : flowType === 'tupss'}">
    <ul *ngIf="subNav && flowType=='tupss'" class="claim-menus mt-3 ml-md-0">
      <li class="claim-menu">
        <a href="#" (click)="fileClaimClick($event)" [class.selected]="filingClaim">
          {{ 'claims.claimMenus.fileNewClaim' | translate }}
        </a>
      </li>
      <li *ngIf="tfcp" class="claim-menu">
        <a href="#" (click)="claimsClick($event)" [class.selected]="!filingClaim">
          {{ 'claims.claimMenus.allClaims' | translate }}
        </a>
      </li>
    </ul>
    <hr *ngIf="flowType == 'tupss'">
    <div *ngIf="((tfcp) && (!currentUrl.startsWith('/tupss/faqs'))
      && (currentUrl.startsWith('/tfcp/claims'))) && isMobile">
      <upsc-policy-select></upsc-policy-select>
    </div>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<upsc-footer></upsc-footer>