import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexappComponent } from './flexapp/flexapp.component';
import { FlexAppRoutingModule } from './flex-app-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FlexLayoutModule } from 'src/app/shared/components/flex-layout/flex-layout.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
// import { SafePipe } from 'src/app/shared/pipes/safe.pipe';

@NgModule({
  declarations: [
    FlexappComponent
  ],
  imports: [
    CommonModule,
    FlexAppRoutingModule,
    SharedModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatSlideToggleModule
  ]
})
export class FlexAppModule { }
