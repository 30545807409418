import { UserService } from '../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountInfo } from '../../shared/models/account-info.interface';
import { LoginService } from '../../services/login.service';
import { IPolicies } from '../../shared/models/policies.interface';
import { ILogger } from '../../shared/models/logger.interface';
import { Title } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AccessDeniedComponent } from 'src/app/shared/dialogs/access-denied/access-denied.component';
import { ErrordialogComponent } from 'src/app/shared/components/errordialog/errordialog.component';
import { UtilityService } from 'src/app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { TaggingService } from 'src/app/tags/tagging.service';
import { Permissions } from 'src/app/shared/models/permissions';
import { AccessPendingComponent } from 'src/app/shared/dialogs/access-pending/access-pending.component';
import { environment } from 'src/environments/environment';
import { PolicyService } from 'src/app/services/policy.service';
import { ScriptService } from 'src/app/services/script.service';
import { tap } from 'rxjs/operators';
import { BillingService } from 'src/app/services/billing.service';

@Component({
  selector: 'upsc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  //Data holders
  public userModel: any = {};
  login = {
    username: "",
    password: ""
  };
  ccpSelfservice = {
    ccp_selfService_Check: false,
    unique_Partner_Name: ""
  };
  public userData: AccountInfo = {};
  public userid: string = '';
  private returnUrl: string;
  policy: IPolicies;
  public permissions: Permissions[] = [];
  recaptchaSiteKey = environment.recaptcha.siteKeyV3;
  recaptchaSecret = environment.recaptcha.secretV3;

  //Form
  UserDetailsForm: UntypedFormGroup;
  formGroup: UntypedFormGroup;

  //Interfaces
  public logInterface: ILogger = {};

  //Scenarios
  isCanadaUser: boolean;
  showContactError = false;
  public loginFailed: boolean = false;
  isChecked: boolean = false;
  loading: boolean = false;
  scriptError: boolean = false;
  mustCompleteRecaptcha: boolean = false; //If their score is too low they must complete a manual recaptcha
  recaptchaError: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private titleService: Title,
    private userService: UserService,
    private dialog: MatDialog,
    private policyService: PolicyService,
    private translateService: TranslateService,
    private taggingService: TaggingService,
    private scriptService: ScriptService,
    private billingService: BillingService
  ) {
    this.isCanadaUser = this.userService.isCanadaUser();
  }

  ngOnInit(): void {
    this.taggingService.view();
    this.titleService.setTitle(this.translateService.instant('login.setTitle'));
    //Clear session at login initialization
    //if the user session is still active, not remove the session data. this is for security scan purpose.
    // this.loginService.removeLocalStorage();
    if (sessionStorage.getItem('userDetails')) {
      if (JSON.parse(sessionStorage.getItem('userDetails')).type == 'my-choice') {  //If user stays on my-choice pages too long, they can get redirected to login page eventually. I just remove the my-choice session data at this time.
        sessionStorage.removeItem('userDetails');
        sessionStorage.removeItem('my-choice-claim-landing');
        sessionStorage.removeItem('track3');
        sessionStorage.removeItem('claimModel');
      } else {
        if (sessionStorage.getItem('policyDetails')) {  //Only redirect if the user has policyDetails. This prevents the scenario: policy search API fails, user refreshes the page, and gets redirected to a javascript error'd dashboard.
          this.router.navigateByUrl('/dashboard');
        }
      }
    }

    this.UserDetailsForm = this.fb.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`'|{}:;!.?\"()\[\]-]{8,45}$/)
      ])],
      userid: [this.userid, Validators.compose([
        Validators.required,
      ])],
      recaptchaControl: [null]
    });

    this.downloadCaptcha();
    // this.scriptService.loadGlia();
  }

  userLogin() {
    this.recaptchaError = false;
    if (!this.UserDetailsForm.controls.recaptchaControl.valid) {
      this.recaptchaError = true;
      this.loading = false;
      return;
    }

    if (this.UserDetailsForm.valid) {
      this.loading = true;
      this.resetErrorMessages();
      this.login.username = this.UserDetailsForm.get('userid').value;
      this.login.password = this.UserDetailsForm.get('password').value;
      //Set User id in localstorage for other screens
      sessionStorage.setItem('userid', this.login.username);
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
      this.loginService.userLogin(this.login).pipe(
        tap(token => {
          if (token.message == 'TooManySessions') {
            throw 'TooManySessions';
          }
        })
      ).subscribe(
        token => {
          //User is able to login & received a token
          sessionStorage.setItem('access_token', token.accessToken);
          sessionStorage.setItem('refresh_token', token.refreshToken);

          this.loginService.userDetails(token.accessToken).subscribe(
            data => {
              //Check if the user has access to the application by seeing if the
              //token has a policy number attached to it
              if (data) {
                this.scriptError = false;
                if ((!data.policyNumber) || (!data.active)) {
                  //No pol num found,account inactive, -deny access,
                  this.loading = false;
                  this.accessDenied();
                } else if ((!data.permissions) || (data.permissions.length <= 0) || (!data.approved)) {
                  //no permissions found,not approved yet,  -deny access.
                  this.loading = false;
                  this.accessPending();
                } else {
                  //Pol num found, allow user in as normal
                  if (data.policyNumber.search(/^\d+-p\d+$/) == 0) {
                    data.policyNumber = data.policyNumber.toUpperCase();
                  }
                  sessionStorage.setItem('userDetails', JSON.stringify(data));
                  this.userService.setUserInfo(data);
                  this.userService.setUserPermissions(data.permissions);
                  if (this.userService.isUserAdjuster()) {
                    this.returnUrl = 'adjusterDashboard'
                  }
                  //Policy Search API about to be called
                  this.userService.getPolicySearch(data.policyNumber).subscribe(
                    policydata => {
                      this.scriptError = false;

                      if (policydata?.message?.toLowerCase() == 'succeeded' && policydata?.data) {
                        this.policy = policydata.data;
                        if (policydata.data.isChannelPartner === true) { // CCP policies check for SSP
                          this.checkPolicyExist(this.policy.policyNumber);
                        }
                        if(policydata.data.policyType.key == 'Flex' && policydata.data.policyType.value == 'Flexible Parcel Insurance' && policydata.data.policySource == 'ISHIPFLEXMC') { // Tupss flex USPS policies check
                          this.returnUrl = '/billing';
                          }
                        
                        sessionStorage.setItem('policyDetails', JSON.stringify(this.policy));
                        this.taggingService.link({
                          link_name: 'login',
                          event_flag: 'login'
                        });
                        if (this.policy.ref1 == 'HK') {
                          this.loading = false;
                          let smartUploadURL = environment.smartUpload.url;
                          this.policyService.getSmartUploadUrl({
                            policyno: policydata.policyNumber,
                            username: this.userService.getUserInfo().firstName
                          }).subscribe(
                            data => {
                              this.scriptError = false;
                              if (data?.url && data.url != '') {
                                smartUploadURL = data.url;
                              }
                              window.location.href = smartUploadURL;
                            },
                            error => {
                              if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
                                this.scriptError = true;
                                this.loading = false;
                              } else {
                                this.scriptError = false;
                                this.loading = false;
                                window.location.href = smartUploadURL;
                              }
                            }
                          );
                        }
                        else {
                          // add DWgetSummary call here before finishing the login process and navigating user in to ONL.
                          // regardless of if this call succeeds or fails, we will still call logUserAndNavigate().
                          let dwRequest = {
                            productSourceSystem: "gw",
                            sourceSystemIdentifierName: "PolicyNumber",
                            sourceSystemIdentifier: policydata?.data?.policyNumber,
                            country: "US",
                            roleType: "string",
                            productType: "string"
                          }
                          this.billingService.DWgetSummary(dwRequest).subscribe(
                            data => {
                              try {
                                if (data?.data?.wallet) {
                                  sessionStorage.setItem('dwDetails', JSON.stringify(data.data.wallet))
                                }
                                this.checkForChildPolicies();
                              } catch (e) {
                                this.checkForChildPolicies();
                              }
                            }, error => {
                              this.checkForChildPolicies();
                            }
                          );
                        }
                      } else {
                        //The account did have a policy number, but there was some error when calling policy search
                        //Could be due to backend services down, also could be due to lowercase p in policy, returning a 204 no content.
                        //Should present a user with an error message, also delete the sessionStorage
                        this.loading = false;
                        this.showContactError = true;
                        sessionStorage.removeItem('access_token');
                        sessionStorage.removeItem('refresh_token');
                        sessionStorage.removeItem('userDetails');
                        sessionStorage.removeItem('userid');
                      }
                    },
                    error => {
                      if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
                        this.scriptError = true;
                        this.loading = false;
                      }
                      else {
                        this.scriptError = false;
                        //Edge or CC services are down.
                        this.loading = false;
                        this.showContactError = true;
                        this.logInterface.routeName = this.router.url;
                        this.logInterface.exceptionMessage = error.toString();
                        this.utilityService.uiLog(this.logInterface).subscribe(
                          message => { },
                          error => { throw error });
                      }
                    }
                  );
                }
              }
            },
            error => {
              if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
                this.scriptError = true;
                this.loading = false;
              }
              else {
                this.scriptError = false;
                this.loading = false;
                this.logInterface.routeName = this.router.url;
                this.logInterface.exceptionMessage = error.toString();
                this.utilityService.uiLog(this.logInterface).subscribe(
                  message => { },
                  error => { throw error });
              }
            });
          //-------------------Decode token end------------------------------------
        },
        error => {
          if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
            this.scriptError = true;
            this.loading = false;
          }
          else if (error == 'TooManySessions') {
            this.loading = false;
            this.scriptError = false;
            this.showContactError = true;
          }
          else {
            this.scriptError = false;
            this.loading = false;
            if (this.UserDetailsForm.controls.userid.dirty && this.UserDetailsForm.controls.password.dirty) {
              this.loginFailed = true;
              this.taggingService.link({
                link_name: 'UPS_Capital_error',
                event_flag: 'error_message',
                error_message: 'You have entered an invalid username or password'
              })
            }
          }
        }
      );
    } else {
      this.loading = false;
    }
  }

  rememberMe(event) {
    if (event.checked) {
      this.isChecked = true;
    }
    else {
      localStorage.removeItem("username");
    }
  }

  checkPolicyExist(policynum) {
    var guestLinkRequest = {
      policyNumber: policynum,
      partnerName: ''
    }
    this.policyService.checkPolicyExist(guestLinkRequest).subscribe(response => {
      if (response && response.data && response.message === 'Found' && response.data.portalType.toLowerCase() === 'ssp') {
        this.ccpSelfservice.ccp_selfService_Check = true;
        this.ccpSelfservice.unique_Partner_Name = response.data.partnerName;
        sessionStorage.setItem("ccpUniquePartnerName", JSON.stringify(this.ccpSelfservice));
      }
    }, error => {
      console.log(error);
    });
  }

  accessDenied() {
    //Access Denied popup will not display if another popup is already open, if we are checking if the system is down, or if the system IS down
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(AccessDeniedComponent);
      dialogRef.afterClosed().subscribe();
    }
  }

  accessPending() {
    //Access Pending popup will not display if another popup is already open, if we are checking if the system is down, or if the system IS down
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(AccessPendingComponent);
      dialogRef.afterClosed().subscribe();
    }
  }

  errorDialog() {
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(ErrordialogComponent);
      dialogRef.afterClosed().subscribe();
    }
  }

  resetErrorMessages() {
    this.showContactError = false;
    this.loginFailed = false;
  }

  tagging(type) {
    if (type == 'help') {
      this.taggingService.link({ link_name: 'need_help_signin' });
    } else if (type == 'signup') {
      this.taggingService.link({ link_name: 'create_account', event_flag: 'create_account_begin' });
    }
  }

  checkForChildPolicies() {
    if (this.userService.isParentPolicy()) {
      this.policyService.getListOfChildPolicies({ parentPolicyNumber: this.userService.getUserInfo().policyNumber }).subscribe(
        data => {
          if (data && data.data && data.data.childPolicies) {
            sessionStorage.setItem('childPolicies', JSON.stringify(data.data.childPolicies));
            this.checkIS4UPSQuoteDetails();
          } else {
            this.checkIS4UPSQuoteDetails();
          }
        }, error => {
          this.checkIS4UPSQuoteDetails();
        }
      );
    } else {
      this.checkIS4UPSQuoteDetails();
    }
  }

  checkIS4UPSQuoteDetails() {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));

    // Flow 1: User does not have TC coverage. We're trying to convert some IS4U policyholders to TC right now, see if their policy exists in the control table.
    if (policyDetails?.hasTCCoverage == false) {
      this.policyService.retrieveIS4UPSInfo(policyDetails?.policyNumber).subscribe(
        data => {
          // Only store value to sessionStorage if they have a pre-approved quote.
          if (data?.data && data?.data?.newRate) {
            sessionStorage.setItem('is4upsQuoteDetails', JSON.stringify(data?.data));
          }
          // this.displayWalletTCsPopup();
          this.logUserAndNavigate();
        }, error => {
          this.logUserAndNavigate();
          // this.displayWalletTCsPopup();
        }
      );
    } else { // Flow #2: They already have TC coverage, just continue logging in.
      this.logUserAndNavigate();
      // this.displayWalletTCsPopup();
    }
  }

  // 9/17/2024: If the user has a wallet but has not accepted T&Cs, business wants us to
  // session transfer to IB to display the terms and conditions pop-up. If the user does not fit
  // the above conditions, proceed as normal. Regardless of if this API is successful or not, proceed
  // with logging the user in.
  // - This logic is not present in the internal login flow (keep only to normal logins)
  // - User must have billing permissions to be redirected (if it's a TCs not accepted flow but they are a claims filer, do not redirect)
  displayWalletTCsPopup() {
    if (this.userService.isWalletUser() && !this.userService.isWalletTCsAccepted() && this.userService.canAccessDigitalWallet()) {
      let request = {
        action: "accesswallet", //Hardcoded
        navigatingSystem: "onl", //Hardcoded
        navigatingSystemRole: "admin", //Hardcoded (assume admin)
        productSystem: "gw", //Hardcoded (not coming from cbp/dd)
        productSystemIdentifier1: this.policy?.policyNumber,
        productSystemIdentifier1Name: "policyno",
        productSystemIdentifier2: this.policy?.accountNumber,
        productSystemIdentifier2Name: "pcAccountNumber",
        productType: "iscomplete", //Hardcoded (unless we want to change later based on databricks policytype value)
        country: "us", //Harcoded (wallet is US only)
        locale: "en", //Hardcoded (wallet is US only)
        userIdentifier1Name: "createdbyuserid",
        userIdentifier1: this.userService.getUserInfo()?.userId?.toString(),
        displayName: this.userService.getUserInfo()?.contactName, //QUESTION: Use user's name or policy holder's name?
        userEmail: this.userService.getUserInfo()?.emailAddress, //QUESTION: Do we want to use user's info here or policy's email?
        objectUID: this.userService.getUserInfo()?.objectUID,
        callBackURL: "https://online.upscapital.com", //Hardcoded (we don't need this field, remove if possible)
        returnURL: "https://online.upscapital.com" //Hardcoded (we don't need this field, remove if possible)
      };
      this.billingService.DWstartSession(request).subscribe(
        data => {
          this.logUserAndNavigate();
          if (data?.data?.url) {
            window.open(data?.data?.url, '_blank');
          }
        }, error => {
          this.logUserAndNavigate();
        }
      );
    } else {
      this.logUserAndNavigate();
    }
  }

  logUserAndNavigate() {
    let request = {
      policyNumber: this.userService.getUserInfo().policyNumber,
      email: this.userService.getUserInfo().emailAddress,
      clientTime: new Date().toJSON()
    }
    this.utilityService.loginLog(request).subscribe(
      data => {
        this.loading = false;
        this.scriptError = false;
        this.scriptService.pendoInit();
        this.router.navigate([this.returnUrl]);
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.loading = false;
        }
        else {
          this.scriptError = false;
          this.loading = false;
          this.scriptService.pendoInit();
          this.router.navigate([this.returnUrl]);
        }
      }
    );
  }

  //Will call recaptcha API and return a score for how likely a user is a bot.
  //closer to 0 is more likely a bot. closer to 1 is more likely a human
  reCAPTCHA(e?) {
    this.loading = true;

    //If user must complete a manual recaptcha, skip the API call.
    if (this.mustCompleteRecaptcha) {
      this.userLogin();
      return;
    }

    grecaptcha.ready(() => {
      grecaptcha.execute(this.recaptchaSiteKey, { action: 'submit' }).then((token) => {

        let request = {
          secret: this.recaptchaSecret,
          response: token,
          remoteip: ''
        }

        this.loginService.recaptchaCheck(request).subscribe(
          data => {
            // data.score = 0.1;
            if (data && data.score <= 0.2) {
              //show manual recaptcha
              this.loading = false;
              this.showReCAPTCHA();
            } else {
              //let user proceed
              this.userLogin();
            }
          }, error => {
            //let user proceed anyway.
            this.userLogin();
          }
        );
      });
    });
  }

  downloadCaptcha() {
    this.scriptService.downloadCaptcha();
  }

  showReCAPTCHA() {
    this.mustCompleteRecaptcha = true;
    this.UserDetailsForm.controls.recaptchaControl.setValidators(Validators.required);
  }
}

