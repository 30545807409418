import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CollapsiblePanelModule } from '../../shared/components/collapsible-panel/collapsible-panel.module';
import { CollapsiblePanel2Module } from '../../shared/components/collapsible-panel2/collapsible-panel2.module';
import { LayoutModule } from '../../shared/components/layout/layout.module';
import { StepperModule } from '../../shared/components/stepper/stepper.module';
import { SharedModule } from '../../shared/shared.module';
import { ClaimsComponent } from './claims.component';
import { ClaimsRoutingModule } from './claims.routing.module';
import { ClaimEditComponent } from './components/edit/claim-edit.component';
import { ClaimEditResolver } from './components/edit/claim-edit.resolver';
import { ClaimListComponent } from './components/list/claim-list.component';
import { ClaimHelpComponent } from './components/help/claim-help.component';
import { DialogContentComponent } from './components/dialog-content/dialog-content.component';
import { AuthService } from '../../core/services/auth.service';
import { AuthGuardService } from '../../core/services/auth-guard.service';
import { UnderscoreConversionPipe } from './pipes/underscore-conversion.pipe';
import { DatePipe, TitleCasePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { LoginService } from '../../services/login.service';
import { PagerService } from '../../services/pager.service';
import { ConfirmCancelComponent } from './components/confirm-cancel/confirm-cancel.component';
import { MobileSearchComponent } from './components/mobile-search/mobile-search.component';
import { ExportComponent } from './dialogs/export/export.component';
import { PayeeComponent } from './components/payee/components/table/payee.component';
import { CreateComponent } from './components/payee/components/create/create.component';
import { NotificationBoxModule } from 'src/app/shared/components/notification-box/notification-box.module';
import { BankingComponent } from './components/payee/components/banking/banking.component';
import { BankSuccessComponent } from './components/payee/components/bank-success/bank-success.component';
import { DetailComponent } from './components/detail/detail.component';
import { ClaimDetailsSearchFormComponent } from 'src/app/shared/components/claimdetails-search-form/claimdetails-search-form.component';
import { DateSearchFormComponent } from 'src/app/shared/components/date-search-form/date-search-form.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { PayeeTableComponent } from 'src/app/shared/components/payee-table/payee-table.component';
import { SelectionCardComponent } from 'src/app/shared/components/selection-card/selection-card.component';
import { DraftListComponent } from './components/draft-list/draft-list.component';
import { DraftConfirmationComponent } from './dialogs/draft-confirmation/draft-confirmation.component';
import { MessageListComponent } from './components/message-list/message-list.component';
import { MessageAdjusterComponent } from './components/message-adjuster/message-adjuster.component';
import { CustomizableDataFormComponent } from 'src/app/shared/components/customizable-data-form/customizable-data-form.component';
import { ReopenConfirmationComponent } from './dialogs/reopen-confirmation/reopen-confirmation.component';
import { DraftDeleteConfirmationComponent } from './dialogs/draft-delete-confirmation/draft-delete-confirmation.component';
import { DeleteConfirmationComponent } from './dialogs/delete-confirmation/delete-confirmation.component';

import { NgChartsModule } from 'ng2-charts';
import { ReopenMessageComponent } from './dialogs/reopen-message/reopen-message.component';

import { Stepper4Module } from 'src/app/shared/components/stepper4/stepper4.module';
import { CollapsiblePanelLightModule } from 'src/app/shared/components/collapsible-panel-light/collapsible-panel-light.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ListPagerModule } from 'src/app/shared/components/list-pager/list-pager.module';

import { CollapsiblePanelWhiteModule } from 'src/app/shared/components/collapsible-panel-white/collapsible-panel-white.module';
@NgModule({
    imports: [
        ClaimsRoutingModule,
        CollapsiblePanelModule,
        CollapsiblePanel2Module,
        CollapsiblePanelLightModule,
        CollapsiblePanelWhiteModule,
        LayoutModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        SharedModule,
        StepperModule,
        Stepper4Module,
        MatDialogModule,
        MatCheckboxModule,
        MatTableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        NotificationBoxModule,
        MatExpansionModule,
        MatListModule,
        NgChartsModule,
        MatSlideToggleModule,
        ListPagerModule
    ],
    exports: [
        ClaimListComponent,
        PayeeTableComponent
    ],
    declarations: [
        ClaimEditComponent,
        ClaimListComponent,
        ClaimsComponent,
        DialogContentComponent,
        ClaimHelpComponent,
        DetailComponent,
        UnderscoreConversionPipe,
        ConfirmCancelComponent,
        MobileSearchComponent,
        ExportComponent,
        PayeeComponent,
        CreateComponent,
        BankingComponent,
        BankSuccessComponent,
        ClaimDetailsSearchFormComponent,
        DateSearchFormComponent,
        PayeeTableComponent,
        SelectionCardComponent,
        DraftListComponent,
        DraftConfirmationComponent,
        CustomizableDataFormComponent,
        ReopenConfirmationComponent,
        DraftDeleteConfirmationComponent,
        DeleteConfirmationComponent,
        ReopenMessageComponent,
    ],
    providers: [
        ClaimEditResolver,
        AuthGuardService,
        AuthService,
        LoginService,
        DatePipe,
        TitleCasePipe,
        PagerService,
        DecimalPipe,
        CurrencyPipe,
        ClaimEditComponent
    ]
})
export class ClaimsModule {}
