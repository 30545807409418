import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { AccountService } from 'src/app/services/account.service';
import { EMAIL_PATTERN } from 'src/app/shared/regex-patterns';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/services/data.service';
import { filter, take } from 'rxjs/operators';
import { DynamicFlowService } from 'src/app/services/dynamic-flow.service';
import { GuestLinkPreview } from 'src/app/shared/models/guest-link-preview';
import { GuestLinkService } from 'src/app/services/guest-link.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { PolicyService } from 'src/app/services/policy.service';
import { iif, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';



@Component({
  selector: 'upsc-customized-landing',
  templateUrl: './customized-landing.component.html',
  styleUrls: ['./customized-landing.component.scss']
})
export class CustomizedLandingComponent {

  customerForm: UntypedFormGroup;
  confirmFormGroup: UntypedFormGroup;
  systemError: boolean = false;
  verifyCodeError: boolean = false;
  policyMatchError: boolean = false;
  shipmentInformationError: boolean = false;
  currentStep = 0;
  loading: boolean = false;
  loading2: boolean = false;
  liabilityError: boolean = false;
  duplicateTrackingNumber: boolean = false;
  isInsuredValueZero: boolean = false;
  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: "" };
  channelPartner = '';
  channelPartnerDisplayName = '';
  previewModel: GuestLinkPreview = {};
  flowType = ''; //sessionStorage.getItem("flowType");
  upsHealthCareDeclaredAmountError: boolean = false;
  trackingNumberNotFound: boolean = false;

  @ViewChild("name1") field1: ElementRef;
  @ViewChild("name2") field2: ElementRef;
  @ViewChild("name3") field3: ElementRef;
  @ViewChild("name4") field4: ElementRef;
  @ViewChild("name5") field5: ElementRef;
  @ViewChild("name6") field6: ElementRef;
  emailAddress: any;
  showSpinner: boolean;
  noPackageEligible: boolean;
  confirmationNumber = '';
  coverageFound: boolean;
  directUrl: boolean;


  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private fb: UntypedFormBuilder,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private accountService: AccountService,
    private claimService: ClaimService,
    private router: Router,
    private userService: UserService,
    private dataService: DataService,
    private dynamicFlowService: DynamicFlowService,
    private taggingService: TaggingService,
    private policyService: PolicyService
  ) {

    this.route.queryParams.subscribe(
      params => {
        if (params) {
          // params = channel partner
        }
      }
    );

    //get channel partner and channel partner display name
    this.channelPartner = this.dynamicFlowService.setChannelPartner();
    this.channelPartnerDisplayName = this.dynamicFlowService.setChannelPartnerName();
    this.flowType = sessionStorage.getItem("flowType");

    this.customerForm = this.fb.group({
      trackingNumber: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.pattern(EMAIL_PATTERN), Validators.required])],
      recaptchaControl: [null, Validators.required],
      terms: [false]
    });

    if (this.flowType !== 'pirateship') {
      this.customerForm.get('terms').setValidators(Validators.requiredTrue);
    }

    this.confirmFormGroup = this.fb.group({
      firstDigit: ['', Validators.required],
      secondDigit: ['', Validators.required],
      thirdDigit: ['', Validators.required],
      fourthDigit: ['', Validators.required],
      fifthDigit: ['', Validators.required],
      sixthDigit: ['', Validators.required]
    });
  }


  ngOnInit(): void {
    /* this.customerForm = this.fb.group({
      trackingNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      recaptchaControl: ['', Validators.required]
    }); */

    this.route.queryParams.subscribe(params => {
      if (this.router.url.startsWith('/pirate-ship') && params.eqp) {
        console.log(params.eqp);
        console.log(this.router.url);
        sessionStorage.setItem('pirateShipLanding', this.router.url);
        this.flowType = 'pirateship';
        this.decryptUserDetails(params.eqp);
        this.titleService.setTitle("Pirate Ship | Claims Portal");

        // Retrieve values from sessionStorage and set form controls
        const paramInfo = sessionStorage.getItem('paramInfo');
        if (paramInfo) {
          const data = JSON.parse(paramInfo);
          // this.customerForm.patchValue({
          //   trackingNumber: data.trackingNumber,
          //   email: data.email
          // });
        }
      }
      else if(this.router.url.startsWith('/pirate-ship')) {
        let paramInfo = JSON.parse(sessionStorage.getItem('paramInfo'));
        if (paramInfo && paramInfo.trackingNumber && paramInfo.email) {
          this.customerForm.controls.trackingNumber.setValue(paramInfo.trackingNumber);
          this.customerForm.controls.email.setValue(paramInfo.email);
          sessionStorage.setItem('email2', paramInfo.email);
          this.customerForm.patchValue({ //updating mat form fields
            trackingNumber: paramInfo.trackingNumber,
            email: paramInfo.email
          });
        }
        else {
          this.flowType = 'pirateship';
          this.titleService.setTitle("Pirate Ship | Claims Portal");
          this.directUrl = true;
        }
      }
      else {
        this.flowType = 'default';
        this.customerForm.addControl('terms', this.fb.control(false, Validators.requiredTrue));
        this.titleService.setTitle("UPS Capital Customized Claims Portal");
      }
    });
  }

  //testing

  decryptUserDetails(eqp: string): void {

    // const sampleData = {
    //   trackingNumber: '2690362',
    //   email: 'jhanvidesai@ups.com',
    //   // token: 'mockedToken'
    // };

    // // sample api response
    // sessionStorage.setItem('paramInfo', JSON.stringify(sampleData));
    // sessionStorage.setItem('emailAddress', sampleData.email);
    // this.emailAddress = sampleData.email;
    // this.customerForm.patchValue({
    //   trackingNumber: sampleData.trackingNumber,
    //   email: sampleData.email
    // });


    let eqpToken = eqp.replace(/ /g, '+'); //start encoding
    localStorage.setItem('eqp', eqpToken);
    this.userService.decryptParams({ token: eqpToken }).subscribe( //decrypting the token
      data => { //trackingNumber and email from decryption
        if (data.trackingNumber && data.email) {
          sessionStorage.setItem('paramInfo', JSON.stringify(data)); //network under paraminfo
          sessionStorage.setItem('emailAddress', data.email);
          this.emailAddress = data.email;
          this.customerForm.controls.trackingNumber.setValue(data.trackingNumber);
          this.customerForm.controls.email.setValue(data.email);
          this.customerForm.patchValue({ //updating mat form fields
            trackingNumber: data.trackingNumber,
            email: data.email
          });
        }
      },
      error => {
        this.showSpinner = false;
        this.systemError = true;
      }
    );
  }

  // for debugging purposes only
  //color = "#0662BB";
  /*changeColor(color: any) {
    if (color == 'green') {
      this.color = "#00857d";
    } else if (color == 'blue') {
      this.color = "#0662BB";
    } else if (color == 'red') {
      this.color = "#DF2901";
    }
  }*/

  //Handles paste event for verification code
  pasted() {
    if (this.field1.nativeElement.value.length > 1) {
      let digits = this.splitText();
      this.focusThis(digits)
    }
  }

  //Determines where the next focus should be for verification code
  focusThis(nextIndex: number, event?) {
    if (nextIndex == 2) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.field1.nativeElement.focus();
      }
      else {
        this.field2.nativeElement.focus();
      }
    } else if (nextIndex == 3) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('secondDigit').setValue(' ');
        this.field1.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('secondDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('secondDigit').setValue(event.key);
        }
        this.field3.nativeElement.focus();
      }

    } else if (nextIndex == 4) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('thirdDigit').setValue(' ');
        this.field2.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('thirdDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('thirdDigit').setValue(event.key);
        }
        this.field4.nativeElement.focus();
      }
    } else if (nextIndex == 5) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('fourthDigit').setValue(' ');
        this.field3.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fourthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fourthDigit').setValue(event.key);
        }
        this.field5.nativeElement.focus();
      }
    } else if (nextIndex == 6) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('fifthDigit').setValue(' ');
        this.field4.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fifthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fifthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
    else if (nextIndex >= 7) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('sixthDigit').setValue(' ');
        this.field5.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('sixthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('sixthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
  }

  //Splits up verification code if user tries to paste
  splitText() {
    var str = this.field1.nativeElement.value;
    var splitted = str.split("");
    this.confirmFormGroup.setValue({
      firstDigit: splitted[0],
      secondDigit: splitted[1] || '',
      thirdDigit: splitted[2] || '',
      fourthDigit: splitted[3] || '',
      fifthDigit: splitted[4] || '',
      sixthDigit: splitted[5] || ''
    })
    return splitted.length;
  }


  getStarted() {
    if (this.customerForm.valid) {
      this.loading = true;
      this.liabilityError = false;
      this.duplicateTrackingNumber = false;
      this.dataService.deleteChannelPartnerShipmentDetails();
      this.dataService.deleteTrack3ShipmentDetails();
      this.policyMatchError = false;
      this.shipmentInformationError = false;
      if (this.directUrl) {
        sessionStorage.setItem('emailAddress', this.email.value);
        sessionStorage.setItem('email2', this.email.value);
      } 
      this.claimService.getShipmentDetails(this.customerForm.controls.trackingNumber.value).subscribe(
        data => {
          if (data?.TrackingNumberDetails?.IsDuplicateTrackingNumber == true) {
            this.loading = false;
            this.duplicateTrackingNumber = true;
          } else {
            if (this.channelPartner == 'inxpress') { //additional business logic needed for inxpress users when we call getShipmentDetails
              this.dataService.gatherChannelPartnerShipmentDetails(this.trackingNumber.value, this.channelPartner).subscribe(
                response => {
                  if (response?.error == 'liability is false') { //if liability is false, do not allow them to continue
                    this.loading = false;
                    this.liabilityError = true;
                  } else if (response?.error == 'inxpress system error') { //if getShipmentDetails fails, for inxpress users, do not allow them to continue
                    this.loading = false;
                    this.systemError = true;
                  } else if (response?.error === 'tracking number not found') {
                    this.loading = false;
                    this.trackingNumberNotFound = true;
                  } else if (response?.message === 'succeeded') {
                    this.trackingNumberNotFound = false;
                    this.systemError = false;
                    this.saveVerificationCode();
                  } else {
                    this.loading = false;
                    this.systemError = true;
                    this.trackingNumberNotFound = false;
                  }
                }, error => {
                  if (error?.errorMessage === 'timeout occurred') {
                    this.loading = false;
                    this.systemError = true;
                  } else {
                    this.loading = false;
                    this.systemError = true;
                  }
                }
              );
            } else if (this.channelPartner == 'pirateship') {
              const decryptrackingNumber = this.customerForm.controls.trackingNumber.value;
              this.dataService.gatherChannelPartnerShipmentDetails(decryptrackingNumber, this.channelPartner).subscribe(
                response => {
                  /*if (response?.error == 'liability is false') {
                    this.loading = false;
                    this.liabilityError = true;
                  } else {
                    this.saveVerificationCode();
                  }*/
                  if (response?.message === 'succeeded') {
                    this.trackingNumberNotFound = false;
                    this.systemError = false;
                    this.saveVerificationCode();
                  } else if (response?.error === 'tracking number not found') {
                    this.loading = false;
                    this.trackingNumberNotFound = true;
                  } else {
                    this.loading = false;
                    this.systemError = true;
                    this.trackingNumberNotFound = false;
                  }
                }, error => {
                  if (error?.errorMessage === 'timeout occurred') {
                    this.loading = false;
                    this.systemError = true;
                  } else {
                    this.loading = false;
                    this.systemError = true;
                  }
                }
              );
            } else { //for non-inxpress users -
              //Gathering here because I want to make sure CP data loads first before everything else. In Ideal scenario it loads after user gets an access token
              this.dataService.gatherChannelPartnerShipmentDetails(this.trackingNumber.value, this.channelPartner).subscribe(
                response => {
                  /*if (response?.error == 'internal error') {
                    this.loading = false;
                    this.systemError = true;
                  } else {
                    this.saveVerificationCode();
                  }*/

                  if (response?.message === 'succeeded') {
                    this.trackingNumberNotFound = false;
                    this.systemError = false;
                    this.saveVerificationCode();
                  } else if (response?.error === 'tracking number not found') {
                    this.loading = false;
                    this.trackingNumberNotFound = true;
                  } else {
                    this.loading = false;
                    this.systemError = true;
                    this.trackingNumberNotFound = false;
                  }
                }, error => {
                  if (error?.errorMessage === 'timeout occurred') {
                    this.loading = false;
                    this.systemError = true;
                  } else {
                    this.loading = false;
                    this.systemError = true;
                  }
                }
              );
            }
          }
        }, error => {
          this.loading = false;
          this.systemError = true;
        }
      );
    }
  }

  saveVerificationCode() {
    let verifyData = JSON.parse(sessionStorage.getItem('verifyData'));
    if(this.channelPartner == 'pirate-ship' && verifyData?.emailAddress == this.email.value) { //no need to verify again as email hasn't changed
        this.userService.updateUserInfo({ trackingNumber: this.trackingNumber.value }); //Stored this so that user could continue to claim detail page
        this.getPolicyInfoAndNavigate();
    }
    else {
      this.createVerificationInterface.data.emailAddress = this.customerForm.get('email').value;
      this.createVerificationInterface.emailFor = "CCPVerificationEmail";
      this.claimService.sendCCPVerificationCodeEmail(this.createVerificationInterface).subscribe(
        data => {
          if ((data.responseMessage).toLowerCase() === 'new verification code created' ||
            (data.responseMessage).toLowerCase() === 'code not yet expired') {
            this.loading = false;
            this.currentStep = 1;
          }
        }, error => {
          this.loading = false;
          this.systemError = true;
        }
      );
    }
  }

  //Send verification code that user entered to api
  checkVerificationCode() {
    this.verifyCodeError = false;
    this.loading2 = true;
    //call the check verification code api if success move ahead
    this.verifyCodeInterface.emailId = this.customerForm.get('email').value;
    this.verifyCodeInterface.toVerify = this.confirmFormGroup.get('firstDigit').value + this.confirmFormGroup.get('secondDigit').value + this.confirmFormGroup.get('thirdDigit').value + this.confirmFormGroup.get('fourthDigit').value + this.confirmFormGroup.get('fifthDigit').value + this.confirmFormGroup.get('sixthDigit').value;
    this.accountService.verifyTupssCode(this.verifyCodeInterface).subscribe(
      data => {
        this.loading2 = false;
        if ((data.message).toLowerCase() === 'verification code matched') {
          this.verifyCodeError = false;
          //User is verified & received a token
          sessionStorage.setItem('access_token', data.accessToken);
          sessionStorage.setItem('customized-claims-landing', JSON.stringify({ email: this.email.value }));
          sessionStorage.setItem('verifyData', JSON.stringify({...data, emailAddress: this.email.value}));
          this.userService.updateUserInfo({ trackingNumber: this.trackingNumber.value }); //Stored this so that user could continue to claim detail page
          this.getPolicyInfoAndNavigate();
        } else {
          this.verifyCodeError = true;
        }
      }, error => {
        if (error.error && error.error.message && error.error.message.toLowerCase() == 'verification code did not match') {
          this.loading2 = false;
          this.verifyCodeError = true;
        } else {
          this.loading2 = false;
          this.verifyCodeError = false;
        }
      }
    );
  }

  cancel() {
    this.loading2 = false;
    this.verifyCodeError = false;
    this.confirmFormGroup.reset();
    this.currentStep = 0;
  }





  getPolicyInfoAndNavigate() {
    if (this.trackingNumber.value.toLowerCase().startsWith('1z')) {
      this.dataService.gatherTrack3ShipmentDetails(this.trackingNumber.value);  //If it is a 1Z, also store the track 3 shipment details. GW needs originScan for 1Z shipments.
    }
    this.dataService.getChannelPartnerShipmentDetails().pipe(
      filter(this.isNonNull), //Guarantees we ignore null values
      take(1) //Guarantees we only receive the value once - so we don't call policy search twice in some cases behavior subject returns same value multiple times.
    ).subscribe(
      shipmentInfo => {
        if (shipmentInfo == null) {
          return;
        }

        if (Number(shipmentInfo?.insuredValue) < 1) {
          if (this.flowType === 'pirateship') {
            this.isInsuredValueZero = false;
          } else {
            this.isInsuredValueZero = true;
            this.currentStep = 0;
            return;
          }
        }

        //If no address info - see if we can get from track 3
        if (!shipmentInfo.destinationAddress || !shipmentInfo.originAddress || !shipmentInfo.destinationCity || !shipmentInfo.originCity) {
          if (this.trackingNumber.value.toLowerCase().startsWith('1z')) {
            this.dataService.getTrack3ShipmentDetails().pipe(
              filter(this.isNonNull),
              take(1)
            ).subscribe(
              data => {
                let track3 = this.dataService._track3ShipmentDetails;
                if (track3 && !track3.noInfoReturned) {
                  let destinationAddress = track3.shipperConsigneeAddress.find(x => x.description.toLowerCase() == 'shipto address');
                  let originAddress = track3.shipperConsigneeAddress.find(x => x.description.toLocaleLowerCase() == 'shipper address');
                  let incompleteShipInfo = shipmentInfo;
                  let completeShipInfo = {
                    ...incompleteShipInfo,
                    destinationAddress: destinationAddress.addressLine,
                    destinationCity: destinationAddress.city,
                    destinationCountryCode: destinationAddress.countryCode,
                    destinationPostalCode: destinationAddress.postalCode,
                    destinationState: destinationAddress.stateProvinceCode,
                    originAddress: originAddress.addressLine,
                    originCity: originAddress.city,
                    originCountryCode: originAddress.countryCode,
                    originPostalCode: originAddress.postalCode,
                    originState: originAddress.stateProvinceCode
                  }
                  this.dataService.setChannelPartnerShipmentDetails(completeShipInfo);
                  this.checkIfPolicyMatch(completeShipInfo);
                } else {
                  // incomplete address info and we could not get from track 3.
                  this.currentStep = 0;
                  this.shipmentInformationError = true;
                  return;
                }
              }
            );
          } else {
            //NON-1Z TN had no address info. Stop.
            this.currentStep = 0;
            this.shipmentInformationError = true;
          }
        } else if (shipmentInfo) {
          //We got all the info from CBP - no additional logic needed so just continue.
          this.checkIfPolicyMatch(shipmentInfo);
        }
      }
    );
  }

  checkIfPolicyMatch(shipmentInfo) {
    //sessionStorage.setItem('channelPartner', JSON.stringify(shipmentDetails));


    let request = {
      partnerName: this.channelPartner,  //Hardcoded to unishippers for now that is all we support
      policyNumber: shipmentInfo.policyNumber
    };

    this.claimService.checkCCPPolicy(request).subscribe(
      response => {
        if (response.data && response.data.message && response.data.message == 'Match') {
          //Continue
          this.policyMatchError = false;
          this.userService.getPolicySearch(shipmentInfo.policyNumber).subscribe(
            policyData => {
              if (policyData?.message?.toLowerCase() != 'succeeded') {
                this.currentStep = 0;
                this.systemError = true;
                return;
              }
              //Continue
              sessionStorage.setItem('policyDetails', JSON.stringify(policyData.data));
              this.userService.updateUserInfo({ policyNumber: shipmentInfo.policyNumber }); //Stored this so that user could continue to claim detail page
              //redirect for ccp to file a claim
              this.dynamicFlowService.redirectToFileClaimCcp();
            }, error => {
              //System error
              this.currentStep = 0;
              this.systemError = true;
            }
          );
        } else {
          //Policy match error
          this.currentStep = 0;
          this.policyMatchError = true;
        }
      }, error => {
        //System error
        this.currentStep = 0;
        this.systemError = true;
      }
    );
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  get trackingNumber() { return this.customerForm.controls.trackingNumber }
  get email() { return this.customerForm.controls.email }

  //Determines whether value is null/undefined or not
  //https://stackoverflow.com/questions/43043517/filter-all-null-values-from-an-observablet/60135405#60135405
  isNonNull<T>(value: T): value is NonNullable<T> {
    return value != null;
  }
}
